import React, { memo } from "react";
import Slides from "..";
import { Flex, Grid, GridItem, Heading, Stack } from "@chakra-ui/react";
import Map from "../../../visualizations/Map";
import TableSlide from "../../../visualizations/tables/TableSlide/TableSlide";
import colors from "../../../../styles/colors";
import TableReductionSlide from "../../../visualizations/tables/TableReductionSlide/TableReductionSlide";
import constants from "../../../../constants";

const LegSlide = ({ leg, downloadSettings, passMapNode }) => (
    <Slides
        bg="dfds.groupblue"
        hasMultiple={false}
    >
        <Flex h="100%" w="100%">
            <Grid w="100%" gridTemplateColumns={`${constants.slides.leg.content.width}px ${constants.slides.leg.map.width}px`}>
                <GridItem>
                    <Stack w="100%" justifyContent="center">
                        <Flex w="100%" px="4" pt="8">
                            <Heading textTransform="none" fontSize={20} color={"white"}>Reduction price</Heading>
                        </Flex>
                        <Flex w="100%" p="4" justifyContent="center">
                            <TableSlide type={constants.leg.LEG} table={{ legs: [leg], includeLanemeters: downloadSettings.isLanemeters }} {...downloadSettings} size="sm" borderColor={colors.dfds.slide.lime} color="white" />
                        </Flex>
                        {leg.reductionTable && (
                            <Flex w="100%" h="100%" p="4" justifyContent="center">
                                <TableReductionSlide leg={leg} {...downloadSettings} />
                            </Flex>
                        )}
                    </Stack>
                </GridItem>
                <GridItem>
                    <Map legs={[leg]} passMapNode={passMapNode} />
                </GridItem>
            </Grid>
        </Flex>
    </Slides>
);

export default memo(LegSlide);