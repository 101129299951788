import React from "react";
import { Route, Routes } from "react-router";
import { Card } from "@chakra-ui/card";
import { Flex } from "@chakra-ui/layout";
import Define from "./Define";
import Outputs from "./Outputs";
import CreateContainer from "../../../containers/CreateContainer";
import Customise from "./Customise";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert";

const Create = () => {
    const routes = [
        { path: "/flows", element: props => <Define {...props} /> },
        { path: "/outputs", element: props => <Outputs {...props} /> },
        { path: "/create/*", element: props => <Customise {...props} /> },
    ];

    const CreateRoutes = props => (
        <Routes>
            {routes.map(route => (
                <Route key={route.path} path={route.path} element={(
                    <>
                        {!!props.error && <ErrorAlert error={props.error} onClick={props.resetError} />}
                        <Flex h="100%" w="100%" flexDirection="column">
                            {route.element(props)}
                        </Flex>
                    </>
                )} />
            ))}
        </Routes>
    );

    return (
        <Card h="100%" w="100%" px="12" py="6" borderRadius="30">
            <CreateContainer>
                <CreateRoutes />
            </CreateContainer>
        </Card>
    )
};

export default Create;