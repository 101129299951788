import React from "react";
import { TableContainer, Table as TableUI, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const Table = ({ headers, rows }) => (
    <TableContainer w="100%">
        <TableUI size="sm">
            <Thead>
                <Tr>
                    {headers.map(header => <Th key={header}>{header}</Th>)}
                </Tr>
            </Thead>
            <Tbody>
                {rows.map((row, rowIndex) => (
                    <Tr key={rowIndex}>
                        {row.map((column, columnIndex) => <Td key={columnIndex}>{column}</Td>)}
                    </Tr>
                ))}
            </Tbody>
        </TableUI>
    </TableContainer>
)

export default Table;