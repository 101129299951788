import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Box } from "@chakra-ui/layout";
import headliness from "../../../headlines";
import Heading from "../../typography/Heading/Heading";
import { useLocation, useParams } from "react-router-dom";
import { breadcrumbs as pathBreadcrumbs } from "../../../paths";

const Header = () => {
    const { pathname } = useLocation();
    const { id } = useParams();
    const arr = pathname === '/' ? ['home'] : pathname.split('/');
    let offset = 1;
    const getBreadcrumbs = (breadcrumbsObj, offset) => {
        const breadcrumbs = breadcrumbsObj[arr[arr.length - offset]];
        if (!breadcrumbs) return;
        if (Array.isArray(breadcrumbs)) {
            return breadcrumbs;
        } else {
            return getBreadcrumbs(breadcrumbs, offset += 1);
        }
    }
    const breadcrumbs = getBreadcrumbs(pathBreadcrumbs, offset);

    return (
        <Box mt="6">
            <Breadcrumb>
                {breadcrumbs && breadcrumbs.map((breadcrumb, index) => (
                    <BreadcrumbItem
                        key={breadcrumb.id}
                        isCurrentPage={index === breadcrumbs.length - 1}
                        isLastChild={index === breadcrumbs.length - 1}
                        color="dfds.gray.softtext"
                        fontWeight={index === breadcrumbs.length - 1 ? "bold" : "medium"}
                    >
                        <BreadcrumbLink href={breadcrumb.path.replace(':id', id)}>{breadcrumb.name}</BreadcrumbLink>
                    </BreadcrumbItem>
                ))}
            </Breadcrumb>
            {breadcrumbs && <Heading size="xl" text={headliness[breadcrumbs[breadcrumbs.length - 1]?.id] || "Your Flows"} />}
        </Box>
    )
};

export default Header;