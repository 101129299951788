import React from "react";
import { Box, Grid, GridItem, Stack, Flex } from "@chakra-ui/react";
import Card from "../../layout/Card/Card";
import { ReactComponent as DistanceIcon } from '../../../assets/icons/distance.svg';
import { ReactComponent as TableIcon } from '../../../assets/icons/table.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/map.svg';
import paths from "../../../paths";
import { useNavigate, useParams } from "react-router-dom";
import OutputsTable from "../../blocks/OutputsTable/OutputsTable";
import OutputsContainer from "../../../containers/OutputsContainer";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import colors from "../../../styles/colors";

const Outputs = props => {
    const { id } = useParams();
    const navigate = useNavigate();
    const options = [
        {
            title: "Create new map",
            text: "Visualise your data showing the flow on a map. Good for providing an overview.",
            onClick: () => navigate(paths.mapSettings.replace(':id', id)),
            icon: <MapIcon fill={colors.dfds.gray.extralight} />
        },
        {
            title: "Create new table",
            text: "Visualise your data using a table. Good for showing scenarios/comparisons.",
            onClick: () => navigate(paths.tableSettings.replace(':id', id)),
            icon: <TableIcon fill={colors.dfds.gray.extralight} />
        },
        {
            title: "Zoom in on leg",
            text: "Zoom in on one of your legs to show a map and table with multiple scenarios.",
            onClick: () => navigate(paths.legSettings.replace(':id', id)),
            icon: <DistanceIcon fill={colors.dfds.gray.extralight} />
        }
    ];

    return (
        <Flex h="100%" w="100%" flexDirection="column">
            <Box maxW={1130} h="100%" w="100%" mx="auto" p="6">
                <Stack gap="2rem" h="100%">
                    <Grid gridTemplateColumns="repeat(3, 1fr)" gap="1rem">
                        {options.map(option => (
                            <GridItem key={option.link}><Card {...option} /></GridItem>
                        ))}
                    </Grid>
                    <Flex h="100%" w="100%">
                        <OutputsContainer {...props}>
                            <OutputsTable />
                        </OutputsContainer>
                    </Flex>
                </Stack>
            </Box>
            <Flex mt="1rem">
                <BackButton onClick={() => navigate(paths.define.replace(":id", id))} />
            </Flex>
        </Flex>
    )
}

export default Outputs;