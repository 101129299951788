import React from "react";
import { Box, FormLabel, Input as ChakraInput } from "@chakra-ui/react";

const Input = ({ label, placeholder, onChange, value, isDisabled }) => {

    return (
        <Box>
            <FormLabel>{label}</FormLabel>
            <ChakraInput
                isDisabled={isDisabled}
                placeholder={placeholder}
                type="number"
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        </Box>
    )
};

export default Input;