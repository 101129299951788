import React from 'react';
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import colors from '../../../styles/colors';



const Tooltip = ({ text }) => (
    <ChakraTooltip
        label={text}
        placement="right-end"
        openDelay={200}
        bg='white'
        color="black"
        borderRadius="30px"
        padding="1rem"
        ml="1rem"
        mb="0.8rem"
    >
        <InfoIcon style={{ fill: colors.dfds.actionblue }} />
    </ChakraTooltip>
);

export default Tooltip;