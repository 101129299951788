import React from "react";
import { Route, Routes } from "react-router-dom";
import MapOutput from "../MapOutput";
import TableOutput from "../TableOutput";
import LegOutput from "../LegOutput";

const Customise = props => {
    const routes = [
        { path: "/map/*", element: props => <MapOutput {...props} /> },
        { path: "/table/*", element: props => <TableOutput {...props} /> },
        { path: "/leg/*", element: props => <LegOutput {...props} /> }
    ];

    return (
        <>
            <Routes>
                {routes.map(route => (
                    <Route key={route.path} path={route.path} element={route.element({ ...props })} />
                ))}
            </Routes>
        </>
    );
};

export default Customise;