import React from "react";
import { Flex, Input, Select, Stack, Text } from "@chakra-ui/react";
import constants, { reductionOptions } from "../../../constants";
import Heading from "../../typography/Heading/Heading";
import Table from "../../visualizations/tables/Table/Table";

const ConfigureSettingsLeg = ({ legs, leg, updateLeg }) => (
    <Stack gap="1rem">
        <Heading size="sm" text="Table 1: Leg overview" textTransform="uppercase" />
        <Text>
            Choose which leg to zoom in on and what fuel type to base the reductions on.
        </Text>
        <Table
            headers={["Leg", "Reduction type", "Reduction price", "Baseline price"]}
            rows={[[
                <Select
                    size="sm"
                    w="15rem"
                    placeholder="Select leg"
                    value={leg.id || ''}
                    onChange={e => updateLeg({ ...legs.find(leg => leg.id === e.target.value) || {}, ...(leg.reductionTable ? { reductionTable: leg.reductionTable } : {}) })}
                >
                    {legs.map(leg => <option value={leg.id}>{leg.places.origin.address} - {leg.places.destination.address}</option>)}
                </Select>,
                <Select
                    size="sm"
                    w="15rem"
                    placeholder="Select fuel type"
                    value={leg.reductionType || ''}
                    onChange={e => updateLeg({ ...leg, reductionType: e.target.value })}
                    isDisabled={!leg.id}
                >
                    {leg.modality && Object.entries(reductionOptions[leg.modality]).map(([key, value]) => (
                        <option value={key}>{value}</option>
                    ))}
                </Select>,
                <Flex gap="0.5rem" alignItems="center">
                    {leg.reductionType ? (
                        <>
                            <Input
                                size="sm"
                                type="number"
                                width="9rem"
                                placeholder="Price"
                                value={leg.reductionPrice || ''}
                                onChange={e => updateLeg({ ...leg, reductionPrice: e.target.value })}
                            />
                            {leg.modality === 'ship' && <Text>€/tonne CO₂e</Text>}
                            {leg.modality === 'truck' && (
                                <Select
                                    size="sm"
                                    placeholder="Select"
                                    width="10rem"
                                    value={leg.priceUnit || ''}
                                    onChange={e => updateLeg({ ...leg, priceUnit: e.target.value })}
                                >
                                    <option value={constants.general.TONNE}>€/tonne CO₂e</option>
                                    <option value={constants.general.TRAILER}>€/trailer CO₂e</option>
                                </Select>
                            )}
                        </>
                    ) : null}
                </Flex>,
                <Flex>
                    {(leg.modality === 'truck' && leg.reductionType) && (
                        <Input
                            size="sm"
                            type="number"
                            width="9rem"
                            value={leg.trailerPrice || ''}
                            onChange={e => updateLeg({ ...leg, trailerPrice: e.target.value })}
                        />
                    )}
                </Flex>
            ]]}
        />
    </Stack>
);

export default ConfigureSettingsLeg;