import React, { memo, useState } from "react";
import Slides from "..";
import TableSlideComponent from '../../../visualizations/tables/TableSlide/TableSlide';
import { Flex, Heading, Stack } from "@chakra-ui/react";

const TableSlide = ({ tables, downloadSettings }) => {
    const [showSlide, setShowSlide] = useState(0);

    if (tables[2].legs.length > 0 && tables[1].legs.length + tables[2].legs.length > 6) {
        return (
            <Slides
                bg="dfds.slide.beige"
                hasMultiple
                slideIndex={showSlide}
                lastSlide={1}
                onClickNav={nav => nav === 'next' ? setShowSlide(showSlide + 1) : setShowSlide(showSlide - 1)}
            >
                <Stack h="100%" w="100%" alignItems="center" justifyContent="center">
                    <Flex w="100%" h="100%" p="4" alignItems="center" justifyContent="center">
                        <TableSlideComponent table={tables[showSlide + 1]} {...downloadSettings} withHighlight hasBorderBottom />
                    </Flex>
                </Stack>
            </Slides>
        )
    }
    return (
        <Slides bg="dfds.slide.beige">
            <Stack h="100%" w="100%" alignItems="center" justifyContent="center">
                <Flex w="100%" px="4" pt="8">
                    <Heading textTransform="none" fontSize={20} color={"black"}>Reduction price</Heading>
                </Flex>
                <Flex w="100%" h="100%" p="4" justifyContent="center">
                    <TableSlideComponent table={tables[1]} {...downloadSettings} withHighlight hasBorderBottom />
                </Flex>
                {tables[2].added && (
                    <Flex w="100%" h="100%" p="4" justifyContent="center" bg="white">
                        <TableSlideComponent table={tables[2]} isSecondTable {...downloadSettings} withHighlight hasBorderBottom />
                    </Flex>
                )}
            </Stack>
        </Slides>
    );
};

export default memo(TableSlide);