import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Select,
    Stack,
    Text,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../paths";
import Distance from "../../forms/Distance/Distance";
import Input from "../../forms/Input/Input";
import PrimaryButton from "../../forms/buttons/PrimaryButton/PrimaryButton";
import constants from "../../../constants";
import Heading from "../../typography/Heading/Heading";
import DNDTable from "../../visualizations/tables/DNDTable/DNDTable";

const Define = ({ legs, addLeg, updateLeg, deleteLeg, searoutes, updateSortLegs }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const initTempLeg = {
        modality: null,
    };
    const [tempLeg, setTempLeg] = useState(initTempLeg);
    const [editIndex, setEditIndex] = useState(null)
    const [disableActionTempleg, setDisableActionTempleg] = useState()

    useEffect(() => {
        switch (true) {
            case !tempLeg.modality:
            case !tempLeg.places?.origin?.address:
            case !tempLeg.places?.destination?.address:
            case tempLeg.modality === "ship" && !(tempLeg.lanemeters || tempLeg.trailers):
            case tempLeg.modality === "truck" && !tempLeg.trailers:
                setDisableActionTempleg(true);
                return;
            default:
                setDisableActionTempleg(false);
        };
    }, [tempLeg]);

    const modalities = ["truck", "ship"];
    const modalityOptions = {
        truck: [
            { value: constants.general.AMBIENT, name: constants.general.strings.AMBIENT },
            { value: constants.general.TEMPERATURE_CONTROLLED, name: constants.general.strings.TEMPERATURE_CONTROLLED },
        ],
        ship: searoutes,
    };

    return (
        <Flex direction="column" w="100%" height="100%">
            <Box h="100%">
                <Stack gap="1rem" overflowY="scroll">
                    <Box>
                        <Stack gap="1rem">
                            <Heading size="sm" text="Add leg" />
                            <Flex gap="2rem">
                                <Select
                                    maxW="sm"
                                    size="sm"
                                    borderColor="dfds"
                                    borderRadius={8}
                                    onChange={(e) => setTempLeg({ modality: e.target.value })}
                                    placeholder="Select Leg Modality"
                                    textTransform="capitalize"
                                    value={tempLeg.modality || ""}
                                >
                                    {modalities.map((modality) => (
                                        <option value={modality}>{modality}</option>
                                    ))}
                                </Select>
                                {tempLeg.modality === 'ship' && (
                                    <Select
                                        maxW="sm"
                                        placeholder="Route"
                                        size="sm"
                                        borderColor="dfds"
                                        borderRadius={8}
                                        textTransform="capitalize"
                                        value={tempLeg.modality === "truck"
                                            ? modalityOptions.truck.findIndex(item => item.value === tempLeg.type)
                                            : modalityOptions.ship.findIndex(item => item.name === tempLeg.places?.route)
                                        }
                                        onChange={(e) => {
                                            let newProps;
                                            const index = parseInt(e.target.value)
                                            if (tempLeg.modality === "truck") {
                                                newProps = { type: modalityOptions[tempLeg.modality][index].value }
                                            } else {
                                                newProps = {
                                                    distance: modalityOptions[tempLeg.modality][index].distance,
                                                    places: {
                                                        route: modalityOptions[tempLeg.modality][index].name,
                                                        origin: null,
                                                        destination: null,
                                                        path: modalityOptions[tempLeg.modality][index].path
                                                    }
                                                }
                                            }
                                            setTempLeg({ ...tempLeg, ...newProps })
                                        }
                                        }
                                    >
                                        {modalityOptions[tempLeg.modality].map((option, index) => (
                                            <option value={index}>{option.name}</option>
                                        ))}
                                    </Select>
                                )}
                            </Flex>
                            {tempLeg.modality === "truck" && (
                                <Distance {...tempLeg} onChange={(leg) => setTempLeg({ ...tempLeg, ...leg })} />
                            )}
                            {tempLeg.modality === "ship" && (
                                <Box>
                                    <FormLabel>Origin Port</FormLabel>
                                    <Select
                                        maxW="sm"
                                        placeholder="Select Origin Port"
                                        size="sm"
                                        borderColor="dfds"
                                        borderRadius={8}
                                        value={tempLeg?.places?.route.split("-").indexOf(tempLeg?.places?.origin?.address)}
                                        onChange={(e) => {
                                            const places = tempLeg.places.route.split("-");
                                            const index = parseInt(e.target.value);
                                            const originIndex = index === 0 ? 0 : tempLeg.places.path.length - 1;
                                            const destinationIndex = index === 0 ? tempLeg.places.path.length - 1 : 0;
                                            setTempLeg({
                                                ...tempLeg,
                                                places: {
                                                    ...tempLeg.places,
                                                    origin: { address: places[index], coordinates: tempLeg.places.path[originIndex] },
                                                    destination: {
                                                        address: places[index === 0 ? 1 : 0], coordinates: tempLeg.places.path[destinationIndex]
                                                    },
                                                },
                                            })
                                        }
                                        }
                                    >
                                        {tempLeg.places && tempLeg.places.route &&
                                            tempLeg.places.route
                                                .split("-")
                                                .map((option, index) => <option value={index}>{option}</option>)}
                                    </Select>
                                </Box>
                            )}
                            {!!tempLeg.modality && (
                                <Box>
                                    <Stack gap="1rem">
                                        {tempLeg.modality === "ship" && <Box maxW="770">
                                            <Text fontSize="sm">
                                                Choose whether to input lane meters or number of trailers. An automatic calculation of 1 trailer = 14 lanemeters will be applied if you wish to visualise both in your final output.
                                            </Text>
                                        </Box>}
                                        <Flex alignItems="end" gap="2rem">
                                            <Box>
                                                <Input
                                                    isDisabled={tempLeg.modality === 'ship' && tempLeg.lanemeters}
                                                    label="Number of Trailers"
                                                    placeholder="Number of Trailers"
                                                    value={tempLeg?.trailers ?? ''}
                                                    onChange={(value) => setTempLeg({ ...tempLeg, trailers: value })}
                                                />
                                            </Box>
                                            {tempLeg.modality === "ship" && (
                                                <>
                                                    <Text textTransform="uppercase" my="0.5rem">
                                                        Or
                                                    </Text>
                                                    <Input
                                                        isDisabled={tempLeg.trailers}
                                                        label="Number of Lanemeters"
                                                        placeholder="Lanemeter"
                                                        value={tempLeg?.lanemeters}
                                                        onChange={(value) =>
                                                            setTempLeg({ ...tempLeg, lanemeters: value })
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Flex>
                                    </Stack>
                                </Box>
                            )}
                            <Box>
                                {editIndex === null ? (
                                    <PrimaryButton
                                        text="Add leg"
                                        isDisabled={disableActionTempleg}
                                        onClick={() => {
                                            addLeg(tempLeg);
                                            setTempLeg(initTempLeg);
                                        }}
                                    />)
                                    :
                                    (<PrimaryButton
                                        text="Update leg"
                                        isDisabled={disableActionTempleg}
                                        onClick={() => {
                                            updateLeg(editIndex, tempLeg);
                                            setTempLeg(initTempLeg);
                                            setEditIndex(null);
                                        }}
                                    />
                                    )}
                            </Box>
                        </Stack>
                    </Box>
                    <Heading size="md" text="Flow" />
                    <DNDTable
                        width="100%"
                        headers={[
                            "Order",
                            "Leg",
                            "Distance",
                            "Type",
                            "# of LM",
                            "# of Trailers",
                            "",
                        ]}
                        rows={legs.map((leg, index) => [
                            { id: leg.id, name: `${leg.places.origin.address} - ${leg.places.destination.address}` },
                            leg.distance,
                            `${leg.modality[0].toUpperCase() + leg.modality.substring(1)}`,
                            leg.lanemeters || "N/A",
                            leg.trailers || "N/A",
                            <Flex direction="column" alignItems="flex-start">
                                <Button
                                    bg="transperent"
                                    color="dfds.actionblue"
                                    textDecoration="underline"
                                    height="auto"
                                    py="0.2rem"
                                    _hover={{ bg: "transperent", color: "dfds.actionbluedark", transition: "200ms" }}
                                    onClick={() => {
                                        setTempLeg(legs[index]);
                                        setEditIndex(index);
                                    }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    bg="transperent"
                                    color="dfds.primaryorange"
                                    textDecoration="underline"
                                    height="auto"
                                    py="0.2rem"
                                    _hover={{ bg: "transperent", color: "dfds.primaryorangedark", transition: "200ms" }}
                                    onClick={() => deleteLeg(index)}
                                >
                                    Delete
                                </Button>
                            </Flex>,
                        ])}
                        onSort={updateSortLegs}
                    />
                </Stack>
            </Box>
            <Flex justify="flex-end" align="flex-end" mt="1rem">
                <PrimaryButton text="Continue" isDisabled={legs.length < 1} onClick={() => navigate(paths.outputs.replace(":id", id))} />
            </Flex>
        </Flex>
    );
};

export default Define;
