import React, { memo, useEffect, useRef, useState } from "react";
import { AbsoluteCenter, Box, Card, IconButton, Text } from "@chakra-ui/react";
import { ReactComponent as ArrowRight } from "../../../assets/icons/right.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/left.svg";
import constants from "../../../constants";

const Slides = ({ children, hasMultiple, slideIndex, lastSlide, onClickNav, bg }) => {
    const ref = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [ratio, setRatio] = useState(0);
    const defaultHeight = constants.slides.size.height;
    const defaultWidth = constants.slides.size.width;

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        const elWidth = ref.current.clientWidth;
        const elHeight = ref.current.clientHeight;
        if (elWidth > elHeight) {
            const newRatio = elHeight / defaultHeight;
            setRatio(newRatio);
            setWidth(newRatio * defaultWidth);
            setHeight("100%");
        } else {
            const newRatio = elWidth / defaultWidth;
            setRatio(newRatio);
            setHeight(newRatio * defaultHeight);
            setWidth("100%");
        }
    };

    return (
        <Box flex={1} pos="relative" ref={ref}>
            <AbsoluteCenter h={height} w={width}>
                {ratio !== 0 && (
                    <>
                        <Card
                            h={defaultHeight}
                            w={defaultWidth}
                            overflow="hidden"
                            style={{ transformOrigin: 'left top', transform: `scale(${ratio})` }}
                        >
                            <Box id="slides-wrapper" bg={bg} h="100%" w="100%">
                                {children}
                            </Box>
                        </Card>
                        {hasMultiple && (
                            <>
                                <IconButton
                                    isRound={true}
                                    icon={<ArrowLeft fill="white" />}
                                    colorScheme="dfds"
                                    pos="absolute"
                                    right="calc(100% + 0.5rem)"
                                    top="50%"
                                    translateY="-50%"
                                    isDisabled={slideIndex === 0}
                                    onClick={() => onClickNav('previous')}
                                />
                                <Box
                                    pos="absolute"
                                    bottom="1rem"
                                    right="1rem"
                                >
                                    <Text fontSize={12} color="white">{slideIndex + 1}/{lastSlide + 1}</Text>
                                </Box>
                                <IconButton
                                    isRound={true}
                                    icon={<ArrowRight fill="white" />}
                                    colorScheme="dfds"
                                    pos="absolute"
                                    left="calc(100% + 0.5rem)"
                                    top="50%"
                                    translateY="-50%"
                                    isDisabled={slideIndex === lastSlide}
                                    onClick={() => onClickNav('next')}
                                />
                            </>
                        )}
                    </>
                )}
            </AbsoluteCenter>
        </Box>
    )
};

export default memo(Slides);