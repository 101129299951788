import React, { cloneElement } from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import { CardBody, Card as ChakraCard } from "@chakra-ui/card";
import Heading from "../../typography/Heading/Heading";
import { IconButton } from "@chakra-ui/button";
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import colors from "../../../styles/colors";

const Card = ({ title, text, icon, onClick, hidePlus, rightTopCorner, rightBottomCorner }) => (
    <ChakraCard
        h="100%"
        py="0.5rem"
        px="1rem"
        boxShadow={`0px 4px 12px ${colors.dfds.black}1a`}
        p="4"
        borderRadius="30px"
        cursor="pointer"
        _hover={{
            bg: "gray.100"
        }}
    >
        <CardBody key={title} p="0">
            {rightTopCorner}
            <Box onClick={onClick}>
                <Box
                    maxH={200}
                    borderStyle="dashed"
                    borderWidth='1px'
                    borderRadius="xl"
                    borderColor="dfds.gray.bluegray"
                    p="3rem"
                    bg="dfds.offwhite"
                    pos="relative"
                >
                    <Flex
                        justifyContent="center"
                    >
                        <Box h="60%" w="60%" minH="60px" minW="60px" maxH="120px" maxW="120px">
                            {cloneElement(icon, { height: '100%', width: '100%' })}
                        </Box>
                        {!hidePlus && (
                            <Flex
                                pos="absolute"
                                h="40%"
                                w="40%"
                                top="60%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                                justifyContent="center"
                                alignItems="end"
                            >
                                <IconButton
                                    h="60px"
                                    w="60px"
                                    boxShadow={`0px 15px 30px -8px ${colors.dfds.purpleblue}`}
                                    borderRadius="40%"
                                    bgColor="dfds.actionblue"
                                    icon={<PlusIcon height="50%" width="50%" />}
                                    _hover={{
                                        background: "dfds.groupblue"
                                    }}
                                />
                            </Flex>
                        )}
                    </Flex>
                </Box>
                <Stack mt="4">
                    <Heading size="md" text={title} align="center" />
                    <Text fontSize="sm" textAlign="center">{text}</Text>
                </Stack>
            </Box>
            {rightBottomCorner}
        </CardBody >
    </ChakraCard>
)


export default Card;