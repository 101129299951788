const paths = {
    home: "/",
    create: "/:id/*",
    define: "/:id/flows",
    outputs: "/:id/outputs",
    mapSettings: "/:id/create/map/configure",
    mapOutput: "/:id/create/map/output",
    tableSettings: "/:id/create/table/configure",
    tableOutput: "/:id/create/table/output",
    legSettings: "/:id/create/leg/configure",
    legOutput: "/:id/create/leg/output"
};

const home = [{ id: 'home', name: 'Home', path: paths.home }];
const flow = { id: 'flow', name: 'Flow', path: paths.define };
const outputs = { id: 'outputs', name: 'Outputs', path: paths.outputs };
const mapSettings = { id: 'mapSettings', name: 'Map Settings', path: paths.mapSettings };
const mapOutput = { id: 'mapOutput', name: 'Map', path: paths.mapOutput };
const tableSettings = { id: 'tableSettings', name: 'Table Settings', path: paths.tableSettings };
const tableOutput = { id: 'tableOutput', name: 'Table', path: paths.tableOutput };
const legSettings = { id: 'legSettings', name: 'Leg Settings', path: paths.tableSettings };
const legOutput = { id: 'legOutput', name: 'Leg', path: paths.legOutput };
export const breadcrumbs = {
    home,
    flows: [...home, { id: 'flow', name: 'Flow', path: paths.define }],
    outputs: [...home, flow, outputs],
    configure: {
        map: [...home, flow, outputs, mapSettings],
        table: [...home, flow, outputs, tableSettings],
        leg: [...home, flow, outputs, legSettings]
    },
    output: {
        map: [...home, flow, outputs, mapSettings, mapOutput],
        table: [...home, flow, outputs, tableSettings, tableOutput],
        leg: [...home, flow, outputs, legSettings, legOutput]
    }
};

export default paths;