import React from "react";
import { Flex, Input, Select, Stack, Text } from "@chakra-ui/react";
import Heading from "../../typography/Heading/Heading";
import Table from "../../visualizations/tables/Table/Table";
import constants from "../../../constants";
import InputAddon from "../../forms/InputAddon/InputAddon";

const ConfigureSettingsTable = ({ table, reductionOptions, onChangeReduction, onChangeLanemeters, updatePrice, updateTrailerPrice, updatePriceUnit, updateReductionSize }) => (
    <Stack gap="1rem" w="100%">
        <Heading size="sm" text={`Table ${table.id}: Table settings`} textTransform="uppercase" />
        <Text>
            Choose which fuel types to base the reduction price on for each leg.
        </Text>
        <Table
            headers={["Leg", "Reduction type", "Reduction size", "Reduction price", "Baseline price"]}
            rows={table.legs.map((leg, index) => ([
                `${leg.places.origin.address}-${leg.places.destination.address}`,
                <Select
                    size="sm"
                    w="15rem"
                    placeholder={`Select ${leg.modality === 'truck' ? 'land' : 'sea'} fuel`}
                    value={leg.reductionType || ''}
                    onChange={e => onChangeReduction(table.id, index, e.target.value)}
                >
                    {Object.entries({ ...reductionOptions[leg.modality], [constants.table.NOT_INCLUDE]: constants.table.strings.NOT_INCLUDE }).map(([key, value]) => (
                        <option value={key}>{value}</option>
                    ))}
                </Select>,
                <Flex>
                    {(leg.reductionType && leg.reductionType !== constants.table.NOT_INCLUDE) && (
                        <InputAddon
                            value={(leg.modality === 'truck' ? leg.reduction?.number : leg.reduction?.percent) || ''}
                            onChange={e => updateReductionSize(table.id, index, e.target.value)}
                            text={leg.modality === 'truck' ? `of ${leg.trailers} trailers` : '%'}
                        />
                    )}
                </Flex>,
                <Flex w="20rem" alignItems="center" gap="0.5rem">
                    {(leg.reductionType && leg.reductionType !== constants.table.NOT_INCLUDE) ? (
                        <>
                            <Input
                                size="sm"
                                type="number"
                                width="9rem"
                                value={leg.reductionPrice || ''}
                                onChange={e => updatePrice(table.id, index, e.target.value)}
                            />
                            {leg.modality === 'ship' && <Text>€/tonne CO₂e</Text>}
                            {leg.modality === 'truck' && (
                                <Select
                                    size="sm"
                                    placeholder="Select"
                                    width="10rem"
                                    value={leg.priceUnit || ''}
                                    onChange={e => updatePriceUnit(table.id, index, e.target.value)}
                                >
                                    <option value={constants.general.TONNE}>€/tonne CO₂e</option>
                                    <option value={constants.general.TRAILER}>€/trailer CO₂e</option>
                                </Select>
                            )}
                        </>
                    ) : null}
                </Flex>,
                <Flex>
                    {(leg.modality === 'truck' && leg.reductionType && leg.reductionType !== constants.table.NOT_INCLUDE) && (
                        <Input
                            size="sm"
                            type="number"
                            width="9rem"
                            value={leg.trailerPrice || ''}
                            onChange={e => updateTrailerPrice(table.id, index, e.target.value)}
                        />
                    )}
                </Flex>
            ]))}
        />
    </Stack>
);

export default ConfigureSettingsTable;