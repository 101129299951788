const headliness = {
    home: "Your Flows",
    flow: "Define Flow",
    outputs: "Choose Output",
    mapSettings: "Map Settings",
    mapOutput: "Preview Map",
    tableSettings: "Table Settings",
    tableOutput: "Preview Table(s)",
    legSettings: "Leg Settings",
    legOutput: "Preview Leg",
};

export default headliness;