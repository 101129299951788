import { cloneElement, memo, useState } from "react";
import { createSlides, getOutputs as getOutputsApi, addOutput as addOutputApi, downloadSlides, downloadOutput as downloadOutputApi, deleteOutput as deleteOutputApi } from "../api";
import constants, { getColumnWidth, legRow, legSlideHeaders, mapSlideHeadlines, mapSlideStrings, tableReductionHeaders, tableReductionRow, tableRow, tableSlideHeaders, totalRow } from "../constants";
import { createMapUrl, getPositionsInSlide, legendsCondition } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";

const OutputsContainer = props => {
    const { getIdTokenClaims } = useAuth0();
    const [mapRef, setMapRef] = useState(null);
    const [error, setError] = useState(false);
    const resetError = () => setError(false);

    const getOutputs = async id => getIdTokenClaims().then(res => {
        if (!!res && !!res.__raw) {
            return getOutputsApi(id, res.__raw);
        } else {
            throw res;
        }
    }).catch(e => e.error);

    const createSlide = async output => {
        const { type } = output;
        let content = {};

        switch (type) {
            case constants.general.MAP: {
                // create map url
                const positions = getPositionsInSlide(props.legs);
                const staticMapUrl = createMapUrl(mapRef, props.legs, { height: constants.slides.size.height, width: constants.slides.size.width / 2 });
                // Create slides
                const { settings: { downloadSettings, option }, totalEmission, totalReduction } = props;
                const { isLanemeters } = downloadSettings;
                const headlines = mapSlideHeadlines(option, totalEmission, totalReduction, isLanemeters);
                let slideLegs = [...props.legs];
                let legsText = slideLegs.map(leg => mapSlideStrings(option, leg, isLanemeters));
                content = { image: staticMapUrl, headlines, legsText, legends: legendsCondition(props.legs), positions };
                break;
            }
            case constants.general.TABLE: {
                const { downloadSettings: { isLanemeters } } = output.tables;
                const tables = [output.tables[1], ...(output.tables[2].added ? [output.tables[2]] : [])];
                content = {
                    tables: tables.map(table => ({
                        columnsWidths: getColumnWidth(constants.table.SEVEN_COLUMNS)
                            .reduce((prev, curr, index) => ({ ...prev, [curr]: { width: curr, indices: [...(prev[curr] ? prev[curr].indices : []), index] } }), {}),
                        rows: [
                            tableSlideHeaders(),
                            ...table.legs.filter(leg => leg.reductionType !== constants.table.NOT_INCLUDE).map(leg => tableRow(leg, isLanemeters)),
                            totalRow(table.legs, isLanemeters)
                        ]
                    }))
                }
                break;
            }
            case constants.general.LEG: {
                const { leg } = output;
                const { settings: { downloadSettings } } = props;
                const { isLanemeters } = downloadSettings;
                const staticMapUrl = createMapUrl(mapRef, [output.leg], { height: constants.slides.size.height, width: constants.slides.leg.map.width });
                const reductionHeaders = leg.reductionTable && tableReductionHeaders(leg);
                const reductionRows = leg.reductionTable && tableReductionRow(leg, isLanemeters);
                const positions = getPositionsInSlide([leg]);
                content = {
                    image: staticMapUrl,
                    tables: [
                        {
                            columnsWidths: getColumnWidth(constants.leg.LEG)
                                .reduce((prev, curr, index) => ({ ...prev, [curr]: { width: curr, indices: [...(prev[curr] ? prev[curr].indices : []), index] } }), {}),
                            rows: [
                                legSlideHeaders(),
                                legRow(leg, isLanemeters)
                            ],
                        },
                        ...(leg.reductionTable ? [{
                            columnsWidths: [{ width: (constants.slides.leg.content.width - 2 * constants.slides.padding.x) / reductionHeaders.length, indices: reductionHeaders.map((v, i) => i) }],
                            rows: [
                                reductionHeaders,
                                ...reductionRows
                            ]
                        }] : [])
                    ],
                    legends: legendsCondition([leg]),
                    positions
                };
                break;
            }
            default:
                return null;
        }
        const date = new Date();

        return createSlides(type, type + date.getFullYear() + date.getMonth() + date.getDate(), content, props.userToken);
    };

    // create slides -> returns id -> add output to DB
    const saveOutput = (flowId, output) => createSlide(output)
        .then(({ id }) => {
            return addOutputApi(flowId, { ...output, presentationId: id }, props.userToken)
                .then(res => {
                    if (res.status !== 200) throw res;
                    return res.data;
                })
        })
        .catch(e => setError(e.error));

    const triggerDownload = res => {
        const url = URL.createObjectURL(res);
        const a = document.createElement("a");
        a.href = url;
        a.download = "slides.pptx";
        a.click();
        a.remove();
    }
    const directDownloadOutput = output => createSlide(output, props.userToken).then(({ id }) => downloadSlides(id, props.userToken)
        .then(res => {
            if (res.error) throw res;
            triggerDownload(res);
        })).catch(e => setError(e.error));

    // create slides -> returns id -> download slide -> remove slides on Drive
    const downloadOutput = id => downloadOutputApi(id, props.userToken)
        .then(res => {
            if (res.error) throw res;
            triggerDownload(res)
        }).catch(e => {
            setError(e.error)
        });

    const deleteOutput = id => deleteOutputApi(id, props.userToken);

    const passMapNode = mapRef => {
        setMapRef(mapRef);
    }

    return cloneElement(props.children, { ...props, error, resetError, passMapNode, getOutputs, saveOutput, downloadOutput, directDownloadOutput, deleteOutput });
};

export default memo(OutputsContainer);