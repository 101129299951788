import React, { memo, useState } from "react";
import { Flex, Grid, GridItem } from "@chakra-ui/layout";
import { Box, Heading, Text } from "@chakra-ui/react";
import Map from "../../../visualizations/Map";
import Slides from "..";
import constants, { mapSlideHeadlines, mapSlideStrings } from "../../../../constants";
import InfoSection from "./InfoSection";

const MapSlide = ({ passMapNode, legs, totalEmission, totalReduction, downloadSettings, option }) => {
    const { isLanemeters } = downloadSettings;
    const [showSlide, setShowSlide] = useState(0);

    const headlines = mapSlideHeadlines(option, totalEmission, totalReduction, isLanemeters);
    let slides = [];
    const size = 3;
    for (let i = 0; i < legs.length; i += size) {
        slides = [...slides, legs.slice(i, i + size)];
    }

    return (
        <Slides
            bg="dfds.groupblue"
            hasMultiple={slides.length > 1}
            slideIndex={showSlide}
            lastSlide={slides.length - 1}
            onClickNav={nav => nav === 'next' ? setShowSlide(showSlide + 1) : setShowSlide(showSlide - 1)}
        >
            <Flex h="100%" w="100%">
                <Grid
                    w="100%"
                    templateAreas={`"map flow" "map legs"`}
                    gridTemplateColumns="1fr 1fr"
                    gridTemplateRows="auto 1fr"
                >
                    <GridItem area="map">
                        <Map legs={legs} passMapNode={passMapNode} />
                    </GridItem>
                    <GridItem area="flow">
                        <Box pt="5" pb="4" mx="6" borderBottom="2px solid" borderColor="dfds.slide.gray">
                            <Heading color="white" fontWeight="bold" fontSize={20}>Emissions overview</Heading>
                            {option === constants.map.DEFAULT_CO2E_EMISSIONS && <Text color="white" fontSize={12} mt="0.5rem"><b>{headlines[0].bold}</b>{headlines[0].normal}</Text>}
                            {option !== constants.map.DEFAULT_CO2E_EMISSIONS && (
                                <>
                                    <Text color="white" fontSize={11} mt="0.5rem"><b>{headlines[0].bold}</b>{headlines[0].normal}</Text>
                                    <Text color="white" fontSize={11}><b>{headlines[1].bold}</b>{headlines[1].normal}</Text>
                                </>
                            )}
                        </Box>
                    </GridItem>
                    <GridItem area="legs">
                        {slides[showSlide].map((leg, index) => (
                            <InfoSection
                                key={leg.id + index}
                                legNumber={showSlide * size + index + 1}
                                borderBottom={(index + 1 !== slides[showSlide].length)}
                                strings={mapSlideStrings(
                                    option,
                                    leg,
                                    isLanemeters
                                )}
                            />
                        ))}
                    </GridItem>
                </Grid>
            </Flex>
        </Slides>
    )
};

export default memo(MapSlide); 