import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const getFlows = token => axios.get(baseURL + '/flows', {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't get your flows for you!" }));

const getFlow = (id, token) => axios.get(baseURL + '/flows/' + id, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(({ error: "Sorry, we couldn't get your flows for you!" }));

const addFlow = (company, name, userId, token) => axios({
    url: baseURL + '/flows',
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        company,
        name,
        userId
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't add the flow for you!" }));

const deleteFlow = (id, token) => axios({
    url: baseURL + '/flows/' + id,
    headers: {
        Authorization: `Bearer ${token}`
    },
    method: 'delete'
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't delete the flow for you!" }));

const getSearoutes = token => axios.get(baseURL + '/searoutes', {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't get searoutes!" }));

const getLegs = (flowId, token) => axios.get(baseURL + '/legs/flow/' + flowId, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't get legs!" }));

const addLeg = (flowId, leg, token) => axios({
    url: baseURL + '/legs',
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        flowId,
        ...leg
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't add this leg for you!" }));

const deleteLeg = (id, token) => axios({
    url: baseURL + '/legs/' + id,
    method: 'delete',
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't delete the leg for you!" }));

const updateLeg = (id, leg, token) => axios({
    url: baseURL + '/legs/' + id,
    method: 'put',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: leg
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't update the leg for you!" }));

const createSlides = async (type, title, content, token) => fetch(baseURL + '/slides', {
    method: 'POST',
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
        type,
        title,
        content
    })
})
    .then(res => {
        if (!res.ok) throw res;
        return res.json();
    })
    .then(data => data)
    .catch(() => ({ error: "Sorry, we couldn't create the slide!" }));

const downloadSlides = async (id, token) => fetch(baseURL + '/slides/' + id, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (!res.ok) throw res;
        return res.blob();
    })
    .catch(() => ({ error: "Sorry, we couldn't download slide(s)!" }));

const downloadOutput = async (id, token) => fetch(baseURL + '/outputs/download/' + id, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (!res.ok) throw res;
        return res.blob();
    })
    .catch(() => ({ error: "Sorry, we couldn't download slide(s)!" }));

const getOutputs = (flowId, token) => axios.get(baseURL + '/outputs/flow/' + flowId, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't get your outputs!" }));

const addOutput = (flowId, output, token) => axios({
    url: baseURL + '/outputs',
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        flowId,
        ...output
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't save the output!" }));

const deleteOutput = (id, token) => axios({
    url: baseURL + '/outputs/' + id,
    method: 'delete',
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't delete the output for you!" }));

const deleteSlides = (id, token) => axios({
    url: baseURL + '/slides/' + id,
    method: 'delete',
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't delete slides!" }));

const getEmissions = token => axios.get(baseURL + '/emissions', {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
        return res.data;
    })
    .catch(() => ({ error: "Sorry, we couldn't get current emissions!" }));

const sortLegs = (flowId, legs, token) => axios({
    url: baseURL + '/legs/sort',
    method: 'put',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        flowId,
        legs
    }
})
    .then(res => {
        if (res.status !== 200) throw res;
    })
    .catch(() => ({ error: "Sorry, we couldn't sort legs!" }));

export {
    getFlows,
    getFlow,
    addFlow,
    deleteFlow,
    getSearoutes,
    getLegs,
    addLeg,
    deleteLeg,
    updateLeg,
    createSlides,
    downloadSlides,
    getOutputs,
    addOutput,
    deleteOutput,
    downloadOutput,
    deleteSlides,
    getEmissions,
    sortLegs
}