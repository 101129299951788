import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Flex, Td, Tr } from '@chakra-ui/react';
import { ReactComponent as Hamburger } from '../../../../assets/icons/hamburger.svg';

const SortableRow = ({ row, index }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef
    } = useSortable({ id: row });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Tr ref={setNodeRef} style={style}>
            <Td
                ref={setActivatorNodeRef}
                {...attributes}
                {...listeners}
            >
                <Flex gap="0.5rem" alignItems="center">
                    <Hamburger />
                    {index}
                </Flex>
            </Td>
            {row.map(column => (
                <Td>{column.name || column}</Td>
            ))}
        </Tr>
    );
}

export default SortableRow;