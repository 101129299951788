import React, { memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { ButtonGroup } from "@chakra-ui/react";
import MapSlide from "../../layout/slides/MapSlide/MapSlide";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import PrimaryButton from "../../forms/buttons/PrimaryButton/PrimaryButton";
import paths from "../../../paths";
import constants from "../../../constants";
import Heading from "../../typography/Heading/Heading";
import Toggle from "../../forms/Toggle/Toggle";
import Tooltip from "../../overlay/Tooltip/Tooltip";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert";

const Output = ({ passMapNode, legs, totalEmission, totalReduction, settings, saveOutput, directDownloadOutput, updateSettings, reductionOptions, error, resetError }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleOnSave = () => {
        setIsSaving(true);
        saveOutput(id, {
            type: constants.general.MAP,
            parameters: [constants.map.strings[settings.option]].join(';')
        }).then(() => setIsSaving(false));
    }

    // Click to download presentation slides
    const handleOnDownload = () => {
        setIsDownloading(true);
        directDownloadOutput({ type: constants.general.MAP }).then(() => setIsDownloading(false));
    };

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            {legs.length > 0 && (
                <MapSlide
                    passMapNode={passMapNode}
                    legs={legs}
                    totalEmission={totalEmission}
                    totalReduction={totalReduction}
                    {...settings}
                    reductionOptions={reductionOptions}
                />
            )}
            <Box pos="absolute" top="1rem" right="2rem">
                <Tooltip text="You can zoom in and out of the map until you are happy with the shown view. What you see is what you will then download." />
            </Box>
            <Stack pt="6" pb="6" gap="1rem">
                <Heading text="Download settings" size="md" />
                <Flex gap="2rem">
                    <Toggle
                        leftLabel={constants.general.strings.TRAILER}
                        rightLabel={constants.general.strings.LANEMETERS}
                        isChecked={settings.downloadSettings.isLanemeters}
                        onChange={e => updateSettings({ ...settings, downloadSettings: { ...settings.downloadSettings, isLanemeters: e.target.checked } })}
                    />
                </Flex>
            </Stack>
            <Flex justifyContent="space-between">
                <Box>
                    <BackButton onClick={() => navigate(paths.mapSettings.replace(':id', id), { state: { settings } })} />
                </Box>
                <ButtonGroup>
                    <PrimaryButton text="Save" onClick={handleOnSave} isLoading={isSaving} isDisabled={isSaving || isDownloading} />
                    <PrimaryButton text="Download" onClick={handleOnDownload} isLoading={isDownloading} isDisabled={isSaving || isDownloading} />
                </ButtonGroup>
            </Flex>
        </>
    )
}

export default memo(Output);