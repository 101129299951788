import React, { useEffect, useState } from 'react';
import { Table, Tbody, Th, Thead } from '@chakra-ui/react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableRow';

const DNDTable = ({ headers, rows, onSort }) => {
    const [items, setItems] = useState([]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setItems(rows)
    }, [rows]);

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                const newArray = arrayMove(items, oldIndex, newIndex);
                onSort(newArray);
                return newArray;
            });
        }
    }

    return (
        <Table>
            <Thead>
                {headers.map(header => <Th>{header}</Th>)}
            </Thead>
            <Tbody>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                    >
                        {items.map((row, index) => <SortableItem key={row} index={index + 1} row={row} />)}
                    </SortableContext>
                </DndContext>
            </Tbody>
        </Table>
    );
}

export default DNDTable;