import constants from "./constants";

export const formatFloat = float => Math.round(float * 100) / 100;

export const shipEmissions = (leg, emissionsFactors) => {
    const { KG_TO_TONNE, LANEMETERS } = constants.general.factors;
    const LMTTW = emissionsFactors[leg.places.route].LMTTW * KG_TO_TONNE;
    const LMWTW = emissionsFactors[leg.places.route].LMWTW * KG_TO_TONNE;
    const TrlTTW = emissionsFactors[leg.places.route].TrlTTW * KG_TO_TONNE;
    const TrlWTW = emissionsFactors[leg.places.route].TrlWTW * KG_TO_TONNE;

    let emissions = {
        unit: {
            TTW: {
                trailer: TrlTTW,
                lanemeter: LMTTW
            },
            WTW: {
                trailer: TrlWTW,
                lanemeter: LMWTW
            }
        }
    }
    if (leg.trailers) {
        return {
            ...emissions,
            leg: {
                TTW: {
                    trailer: emissions.unit.TTW.trailer * leg.trailers,
                    lanemeter: emissions.unit.TTW.lanemeter * leg.trailers * LANEMETERS
                },
                WTW: {
                    trailer: emissions.unit.WTW.trailer * leg.trailers,
                    lanemeter: emissions.unit.WTW.lanemeter * leg.trailers * LANEMETERS
                }
            }
        };
    } else {
        return {
            ...emissions,
            leg: {
                TTW: {
                    trailer: emissions.unit.TTW.trailer * Math.ceil(leg.lanemeters / LANEMETERS),
                    lanemeter: emissions.unit.TTW.lanemeter * leg.lanemeters
                },
                WTW: {
                    trailer: emissions.unit.WTW.trailer * Math.ceil(leg.lanemeters / LANEMETERS),
                    lanemeter: emissions.unit.WTW.lanemeter * leg.lanemeters
                }
            }
        };
    }
}

export const truckEmissions = leg => {
    const { trailers, distance } = leg;
    const km = parseFloat(distance.replace(',', '').replace(' km'));
    const { CONSUMPTION, DIESEL_EQ, ENGINE_EF, WTW_DIESEL, TTW_DIESEL, KG_TO_TONNE } = constants.general.factors;
    const base = km * CONSUMPTION * DIESEL_EQ * ENGINE_EF * KG_TO_TONNE;
    const WTWEmission = base * WTW_DIESEL;
    const TTWEmission = base * TTW_DIESEL;

    return {
        unit: {
            WTW: { trailer: WTWEmission },
            TTW: { trailer: TTWEmission }
        },
        leg: {
            WTW: { trailer: WTWEmission * trailers },
            TTW: { trailer: TTWEmission * trailers }
        }
    };
};

export const truckReductions = (leg, trailers) => {
    const { emission } = leg;
    const reductionFactor = parseInt(trailers) / leg.trailers;

    // QUESTION: Should not reduced be calculated
    return {
        unit: {
            TTW: { trailer: emission.unit.TTW.trailer * reductionFactor },
            WTW: { trailer: emission.unit.WTW.trailer * reductionFactor - (emission.unit.WTW.trailer - emission.unit.TTW.trailer) }
        },
        leg: {
            TTW: { trailer: emission.leg.TTW.trailer * reductionFactor },
            WTW: { trailer: emission.leg.WTW.trailer * reductionFactor - (emission.leg.WTW.trailer - emission.leg.TTW.trailer) }
        }
    };
};

// calc TTW: set WTWEmission = null
export const shipReductions = (leg, reductionFactor) => {
    const { emission } = leg;
    const unitTTWEmission = emission.unit.TTW;
    const unitWTWEmission = emission.unit.WTW; // emission TTW * factor
    const legTTWEmission = emission.leg.TTW;
    const legWTWEmission = emission.leg.WTW;

    return {
        unit: {
            TTW: {
                trailer: unitTTWEmission.trailer * reductionFactor,
                lanemeter: unitTTWEmission.lanemeter * reductionFactor,
            },
            WTW: {
                trailer: unitWTWEmission.trailer * reductionFactor - (unitWTWEmission.trailer - unitTTWEmission.trailer),
                lanemeter: unitWTWEmission.lanemeter * reductionFactor - (unitWTWEmission.lanemeter - unitTTWEmission.lanemeter),
            }
        },
        leg: {
            TTW: {
                trailer: legTTWEmission.trailer * reductionFactor,
                lanemeter: legTTWEmission.lanemeter * reductionFactor
            },
            WTW: {
                trailer: legWTWEmission.trailer * reductionFactor - (legWTWEmission.trailer - legTTWEmission.trailer),
                lanemeter: legWTWEmission.lanemeter * reductionFactor - (legWTWEmission.lanemeter - legTTWEmission.lanemeter),
            }
        }
    }
};

export const enrichLegsWithEmissions = (legs, emissions) => {
    let newLegs = [];
    let totalEmission = { TTW: { trailer: 0, lanemeter: 0 }, WTW: { trailer: 0, lanemeter: 0 } };

    for (let i = 0; i < legs.length; i++) {
        const leg = legs[i];
        const emission = leg.modality === 'ship' ? shipEmissions(leg, emissions) : truckEmissions(leg)
        newLegs = [...newLegs, { ...leg, emission }];
        totalEmission = {
            TTW: {
                trailer: totalEmission.TTW.trailer + emission.leg.TTW.trailer,
                lanemeter: totalEmission.TTW.lanemeter + (leg.modality === 'ship' ? emission.leg.TTW.lanemeter : emission.leg.TTW.trailer)
            },
            WTW: {
                trailer: totalEmission.WTW.trailer + emission.leg.WTW.trailer,
                lanemeter: totalEmission.WTW.lanemeter + (leg.modality === 'ship' ? emission.leg.WTW.lanemeter : emission.leg.WTW.trailer)
            },
        };
    };
    return {
        totalEmission,
        legs: newLegs
    };
};

const init = { trailer: 0, lanemeter: 0 };
export const initReduction = { TTW: init, WTW: init, percent: { TTW: init, WTW: init } };
export const totalReduction = (legs, totalEmission) => {
    return legs.reduce((prev, curr) => {
        const TTWTrailer = prev.TTW.trailer + (curr.reduction?.tonne.leg.TTW.trailer || 0);
        const WTWTrailer = prev.WTW.trailer + (curr.reduction?.tonne.leg.WTW.trailer || 0);
        const TTWLanemeter = prev.TTW.lanemeter + (curr.modality === 'ship' ? (curr.reduction?.tonne.leg.TTW.lanemeter || 0) : (curr.reduction?.tonne.leg.TTW.trailer || 0));
        const WTWLanemeter = prev.WTW.lanemeter + (curr.modality === 'ship' ? (curr.reduction?.tonne.leg.WTW.lanemeter || 0) : (curr.reduction?.tonne.leg.WTW.trailer || 0));
        return {
            TTW: {
                trailer: TTWTrailer,
                lanemeter: TTWLanemeter,
            },
            WTW: {
                trailer: WTWTrailer,
                lanemeter: WTWLanemeter,
            },
            percent: {
                TTW: {
                    trailer: TTWTrailer / totalEmission.TTW.trailer * 100,
                    lanemeter: TTWLanemeter / totalEmission.TTW.lanemeter * 100
                },
                WTW: {
                    trailer: WTWTrailer / totalEmission.WTW.trailer * 100,
                    lanemeter: WTWLanemeter / totalEmission.WTW.lanemeter * 100
                }
            }
        };
    }, initReduction)
};