import React, { useEffect, useState } from "react";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../paths";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import ConfigureSettingsTable from "../../blocks/ConfigureSettingsTable/ConfigureSettingsTable";
import constants from "../../../constants";
import Add from "../../forms/Add/Add";
import Remove from "../../forms/Remove/Remove";

const Configure = ({ tables, emissionsTimeStamp, reductionOptions, updateReductionType, toggleSecondTable, toggleLanemeters, updatePrice, updatePriceUnit, updateReductionSize, updateTrailerPrice }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isCreateDisabled, setIsCreateDisabled] = useState(true);

    useEffect(() => {
        const check = legs => legs.filter(leg => (
            (leg.reductionType === constants.table.NOT_INCLUDE) ||
            (leg.reductionType !== constants.table.NOT_INCLUDE && leg.modality === 'ship' && leg.reductionPrice && leg.reduction?.percent > 0) ||
            (leg.reductionType !== constants.table.NOT_INCLUDE && leg.modality === 'truck' && leg.reductionPrice && leg.priceUnit && leg.reduction?.percent > 0 && leg.trailerPrice)
        ));
        if ((!tables[2].added && tables[1].legs.length === check(tables[1].legs).length) ||
            (tables[2].added && tables[1].legs.length === check(tables[1].legs).length && tables[2].legs.length === check(tables[2].legs).length)) {
            setIsCreateDisabled(false);
        } else {
            setIsCreateDisabled(true);
        }
    }, [tables]);

    return (
        <>
            <Flex h="100%" w="100%" py="6">
                <Stack gap="2rem" w="100%">
                    <ConfigureSettingsTable
                        table={tables[1]}
                        emissionsTimeStamp={emissionsTimeStamp}
                        reductionOptions={reductionOptions}
                        onChangeReduction={updateReductionType}
                        onChangeLanemeters={toggleLanemeters}
                        updatePrice={updatePrice}
                        updatePriceUnit={updatePriceUnit}
                        updateReductionSize={updateReductionSize}
                        updateTrailerPrice={updateTrailerPrice}
                    />
                    {tables[2].added ? (
                        <>
                            <ConfigureSettingsTable
                                table={tables[2]}
                                reductionOptions={reductionOptions}
                                onChangeReduction={updateReductionType}
                                onChangeLanemeters={toggleLanemeters}
                                updatePrice={updatePrice}
                                updatePriceUnit={updatePriceUnit}
                                updateReductionSize={updateReductionSize}
                            />
                            <Remove onClick={toggleSecondTable} />
                        </>
                    ) : (
                        <Add text="Add second table" onClick={toggleSecondTable} />
                    )}
                </Stack>
            </Flex >
            <Flex justifyContent="space-between">
                <BackButton onClick={() => navigate(paths.outputs.replace(':id', id))} />
                <Button
                    isDisabled={isCreateDisabled}
                    colorScheme="dfds"
                    onClick={() => navigate(paths.tableOutput.replace(':id', id), { state: { tables } })}
                >
                    Create
                </Button>
            </Flex>
        </>
    )
};

export default Configure;