import React, { useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Input, Box, Stack, FormLabel, Popover, PopoverTrigger, IconButton, PopoverContent, PopoverBody, Flex, Select, Image } from "@chakra-ui/react";
import Card from "../../layout/Card/Card";
import { ReactComponent as Distance } from "../../../assets/icons/distance.svg"
import Alert from "../../overlay/alert";
import { useNavigate } from "react-router-dom";
import paths from "../../../paths";
import colors from "../../../styles/colors";
import { ReactComponent as GearIcon } from "../../../assets/icons/gear.svg";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert";

const Home = ({ flows: propsFlows, addFlow, deleteFlow, error, resetError, user }) => {
    const navigate = useNavigate();
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [newCompany, setNewCompany] = useState("");
    const [newName, setNewName] = useState("");
    const [flows, setFlows] = useState([]);
    const [filterCompany, setFilterCompany] = useState('');
    const [filterUser, setFilterUser] = useState('');

    useEffect(() => {
        setFlows(propsFlows);
        if (filterCompany || filterUser) handleOnFilterInit(filterCompany, filterUser);
    }, [propsFlows]);

    const handleOnCreateFlow = () => addFlow(newCompany, newName)
        .then(id => !!id && navigate(paths.define.replace(":id", id)));

    const handleOnFilterInit = (filterCompany, filterUser) => {
        let newFlows = [...propsFlows];
        if (filterCompany) {
            newFlows = newFlows.filter(flow => flow.company.toLowerCase() === filterCompany);
            setFilterCompany(filterCompany);
        };
        if (filterUser) {
            newFlows = newFlows.filter(flow => !!flow.userNickname && flow.company.toLowerCase() === filterUser);
            setFilterCompany(filterUser);
        };
        setFlows(newFlows);
    };

    const handleOnFilter = (type, value) => {
        let newFlows = [...propsFlows];
        switch (true) {
            case type === 'company' && value && filterUser === '':
                newFlows = propsFlows.filter(flow => flow.company.toLowerCase() === value);
                setFilterCompany(value);
                break;
            case type === 'company' && value && filterUser !== '':
                newFlows = newFlows.filter(flow => flow.company.toLowerCase() === value);
                newFlows = newFlows.filter(flow => !!flow.userNickname && flow.company.toLowerCase() === filterUser);
                setFilterCompany(value);
                break;
            case type === 'user' && value && filterCompany !== '':
                newFlows = newFlows.filter(flow => flow.company.toLowerCase() === filterCompany);
                newFlows = newFlows.filter(flow => !!flow.userNickname && flow.userNickname.toLowerCase() === value);
                setFilterUser(value);
                break;
            case type === 'user' && value && filterUser === '':
                setFilterUser(value);
                newFlows = propsFlows.filter(flow => !!flow.userNickname && flow.userNickname.toLowerCase() === value);
                break;
            case type === 'company' && !value && filterUser !== '':
                newFlows = newFlows.filter(flow => !!flow.userNickname && flow.userNickname.toLowerCase() === filterUser);
                setFilterCompany('');
                break;
            case type === 'user' && !value && filterCompany !== '':
                newFlows = newFlows.filter(flow => flow.company.toLowerCase() === filterCompany);
                setFilterUser('');
                break;
            case type === 'company' && !value && filterUser === '':
            case type === 'user' && !value && filterCompany === '':
            default:
                setFilterCompany('');
                setFilterUser('');
                break;
        };
        setFlows(newFlows);
    }

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            <Grid templateColumns={{ xl: 'repeat(5, 1fr)', md: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)' }} gap="1rem">
                <GridItem gridColumn="1 / -1">
                    <Flex gap="1rem">
                        <Select
                            size="md"
                            maxW="20rem"
                            bg="white"
                            borderColor="dfds.groupblue"
                            placeholder="Filter by client"
                            value={filterCompany}
                            onChange={e => handleOnFilter('company', e.target.value)}
                        >
                            {propsFlows
                                .filter((flow, index, newFlows) => index === newFlows.findIndex(f => f.company.toLowerCase() === flow.company.toLowerCase())) // remove duplicates of company name
                                .map(flow => (
                                    <option value={flow.company.toLowerCase()}>{flow.company}</option>
                                ))}
                        </Select>
                        <Select
                            size="md"
                            maxW="20rem"
                            bg="white"
                            borderColor="dfds.groupblue"
                            placeholder="Filter by user"
                            value={filterUser}
                            onChange={e => handleOnFilter('user', e.target.value)}
                        >
                            {propsFlows
                                .filter((flow, index, newFlows) => index === newFlows.findIndex(f => !!flow.userNickname && f.userNickname.toLowerCase() === flow.userNickname.toLowerCase())) // remove duplicates of nickname
                                .map(flow => (
                                    <option value={flow.userNickname.toLowerCase()}>{flow.userNickname}</option>
                                ))}
                        </Select>
                    </Flex>
                </GridItem>
                <GridItem>
                    <Card
                        title={"New Flow"}
                        text={"Set up a new flow and define your desired output"}
                        icon={<Distance fill={colors.dfds.gray.extralight} />}
                        onClick={() => setIsCreateOpen(true)}
                    />
                </GridItem>
                {flows.map(flow => (
                    <GridItem key={flow.id}>
                        <Card
                            title={flow.company}
                            text={flow.name}
                            icon={<Distance fill={colors.dfds.actionblue} />}
                            hidePlus={true}
                            onClick={() => navigate(paths.define.replace(":id", flow.id))}
                            rightTopCorner={(
                                <Flex justifyContent="right">
                                    <Popover>
                                        <PopoverTrigger>
                                            <IconButton
                                                bg="none"
                                                _hover={{ svg: { fill: colors.dfds.groupblue } }}
                                                icon={<GearIcon fill={colors.dfds.actionblue} />}
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent w="100%">
                                            <PopoverBody
                                                fontWeight="medium"
                                                color="dfds.primaryorange"
                                                onClick={() => deleteFlow(flow.id)}
                                                _hover={{ color: colors.dfds.primaryorangedark }}
                                            >
                                                Delete
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </Flex>
                            )}
                            rightBottomCorner={(
                                <Flex justifyContent="right">
                                    {flow.userPicture && (
                                        <Image
                                            h="2rem"
                                            w="2rem"
                                            borderRadius="100%"
                                            src={flow.userPicture}
                                            title={flow.userNickname}
                                        />
                                    )}
                                </Flex>
                            )}
                        />
                    </GridItem>
                ))}
            </Grid>
            <Alert
                isOpen={isCreateOpen}
                title="New Flow"
                primaryButtonIsDisabled={newCompany === "" || newName === ""}
                primaryButtonText="Create"
                primaryButtonOnClick={handleOnCreateFlow}
                secondaryButtonText="Cancel"
                secondaryButtonOnClick={() => setIsCreateOpen(false)}
                body={(
                    <Stack>
                        <Box>
                            <FormLabel>Company</FormLabel>
                            <Input value={newCompany} onChange={e => setNewCompany(e.target.value)} />
                        </Box>
                        <Box>
                            <FormLabel>Flow Name</FormLabel>
                            <Input value={newName} onChange={e => setNewName(e.target.value)} />
                        </Box>
                    </Stack>
                )}
            />
        </>
    );
};

export default Home;
