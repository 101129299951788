import { cloneElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getEmissions } from "../api";
import { enrichLegsWithEmissions, shipReductions, totalReduction as calcTotalReduction, truckReductions, initReduction } from "../calculations";
import { enrichLegsWithPaths } from "../helpers";
import constants, { reductionOptions } from "../constants";

const CustomiseMapContainer = ({ children, legs: propsLegs, userToken }) => {
    const location = useLocation();
    const initSettings = {
        option: '',
        downloadSettings: {
            isKg: false,
            isLanemeters: null
        },
    };
    const [settings, setSettings] = useState(initSettings);
    const [legs, setLegs] = useState([]);
    const [totalEmission, setTotalEmission] = useState({ TTW: 0, WTW: 0 });
    const [totalReduction, setTotalReduction] = useState(initReduction);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (propsLegs.length > 0) {
            getEmissions(userToken).then(async res => {
                if (res.error) throw res;
                const { legs } = await enrichLegsWithPaths(propsLegs, userToken);
                const { legs: newLegs, totalEmission } = enrichLegsWithEmissions(legs, res.emissions);
                setLegs(newLegs);
                setTotalEmission(totalEmission);
                for (let i = 0; i < legs.length; i++) {
                    const leg = legs[i];
                    if (leg.modality === 'ship') {
                        setSettings({
                            ...settings,
                            downloadSettings: { ...settings.downloadSettings, isLanemeters: leg.lanemeters > 0 }
                        })
                        return;
                    }
                }
            }).catch(e => {
                setError(e.error);
            });
        }
    }, [propsLegs]);

    useEffect(() => {
        setSettings({
            ...initSettings,
            ...location.state?.settings
        } || initSettings);
    }, [location.state]);

    const updateSettings = settings => setSettings(settings);

    const updateReduction = (type, index, value) => {
        let newLeg = { ...legs[index] };
        switch (type) {
            case 'reductionFactor':
                newLeg = {
                    ...newLeg,
                    reduction: newLeg.modality === 'ship' ? {
                        percent: value,
                        tonne: shipReductions(newLeg, value / 100)
                    } : {
                        number: value,
                        percent: value / newLeg.trailers * 100,
                        tonne: truckReductions(newLeg, value)
                    }
                };
                const newLegs = [...legs.slice(0, index), newLeg, ...legs.slice(index + 1)];
                setTotalReduction(calcTotalReduction(newLegs, totalEmission));
                setLegs(newLegs);
                break;
            case 'fuelType':
                newLeg = {
                    ...newLeg,
                    fuelType: value
                };
                if (value === constants.map.DEFAULT_FUEL) {
                    newLeg = {
                        ...newLeg,
                        reduction: newLeg.modality === 'ship' ? {
                            percent: '',
                            tonne: shipReductions(newLeg, 0)
                        } : {
                            number: '',
                            percent: 0,
                            tonne: truckReductions(newLeg, 0)
                        }
                    };
                }
                setLegs([...legs.slice(0, index), newLeg, ...legs.slice(index + 1)]);
                break;
            default:
                return;
        }
    }

    const resetError = () => setError(false);

    return cloneElement(children, { error, resetError, legs, reductionOptions, totalEmission, totalReduction, settings, updateSettings, updateReduction });
};

export default CustomiseMapContainer;