import { useState } from "react";
import { useLoadScript } from "@react-google-maps/api";

const GoogleScriptContainer = ({ children }) => {
    const [libraries] = useState(["places", "geocoding", "maps", "marker"]);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries
    });

    if (!isLoaded) return null;

    return (children);
};

export default GoogleScriptContainer;