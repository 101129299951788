import React, { useState } from "react";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { ButtonGroup } from "@chakra-ui/react";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../paths";
import TableSlide from "../../layout/slides/TableSlide/TableSlide";
import constants from "../../../constants";
import PrimaryButton from "../../forms/buttons/PrimaryButton/PrimaryButton";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert";
import Toggle from "../../forms/Toggle/Toggle";
import Heading from "../../typography/Heading/Heading";

const Output = ({ tables, saveOutput, directDownloadOutput, error, resetError, toggleDownloadSettings }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleOnSave = () => {
        setIsSaving(true);
        let parameters;
        const createParameters = legs => legs
            .map(leg => leg.reductionType)
            .filter(leg => leg !== constants.table.NOT_INCLUDE)
            .filter((reductionType, index, newReductionTypes) => index === newReductionTypes.indexOf(reductionType))
            .map(reductionType => constants.table.strings[reductionType])
            .join(', ');
        if (tables[2].added) {
            parameters = `Reductions Table 1: ${createParameters(tables[1].legs)} | Reductions Table 2: ${createParameters(tables[2].legs)}`;
        } else {
            parameters = `Reductions: ${createParameters(tables[1].legs)}`;
        }
        saveOutput(id, {
            type: constants.general.TABLE,
            parameters,
            tables
        }).then(() => setIsSaving(false));
    }

    const handleOnDownload = () => {
        setIsDownloading(true);
        directDownloadOutput({
            type: constants.general.TABLE,
            tables
        }).then(() => setIsDownloading(false));
    }

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            <TableSlide
                tables={{
                    1: { ...tables[1], legs: tables[1].legs.filter(leg => leg.reductionType !== constants.table.NOT_INCLUDE) },
                    2: { ...tables[2], legs: tables[2].added ? tables[2].legs.filter(leg => leg.reductionType !== constants.table.NOT_INCLUDE) : [] }
                }}
                downloadSettings={tables.downloadSettings}
            />
            <Stack pt="6" pb="6" gap="1rem">
                <Heading text="Download settings" size="md" />
                <Flex gap="2rem">
                    <Toggle
                        leftLabel={constants.general.strings.TRAILER}
                        rightLabel={constants.general.strings.LANEMETERS}
                        isChecked={tables.downloadSettings.isLanemeters}
                        onChange={e => toggleDownloadSettings('isLanemeters', e.target.checked)}
                    />
                </Flex>
            </Stack>
            <Flex justifyContent="space-between">
                <Box>
                    <BackButton onClick={() => navigate(paths.tableSettings.replace(':id', id), { state: { tables } })} />
                </Box>
                <ButtonGroup>
                    <PrimaryButton text="Save" onClick={handleOnSave} isLoading={isSaving} isDisabled={isSaving || isDownloading} />
                    <PrimaryButton text="Download" onClick={handleOnDownload} isLoading={isDownloading} isDisabled={isSaving || isDownloading} />
                </ButtonGroup>
            </Flex>
        </>
    )
}

export default Output;