import React, { useEffect, useRef, useState } from "react";
import { AbsoluteCenter, Box, Card, Flex, FormLabel, Input, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { DistanceMatrixService, StandaloneSearchBox } from "@react-google-maps/api";
import { ReactComponent as DistanceIcon } from "../../../assets/icons/distance.svg";

const Distance = ({ onChange, places: propsPlaces }) => {
    const originRef = useRef();
    const destinationRef = useRef();
    const [distance, setDistance] = useState(null);
    const [places, setPlaces] = useState({ path: [{}, {}], origin: { address: null, coordinates: null }, destination: { address: null, coordinates: null } });

    useEffect(() => {
        setPlaces({
            ...places,
            origin: propsPlaces?.origin || places.origin,
            destination: propsPlaces?.destination || places?.destination,
            path: propsPlaces?.path || places.path
        });
    }, []);

    useEffect(() => {
        onChange({ places, distance });
    }, [distance]);

    const handlePlaceChange = (type, ref) => {
        const [place] = ref.current.getPlaces();
        if (place) {
            const { geometry: { location } } = place;
            const coordinates = {
                lat: location.lat(),
                lng: location.lng()
            }
            const path = type === "origin" ? [coordinates, places.path[1]] : [places.path[0], coordinates];
            const newPlaces = {
                ...places,
                path,
                [type]: {
                    address: place.vicinity,
                    coordinates
                }
            };
            setPlaces(newPlaces);
        }
    };

    return (
        <Flex gap="2rem">
            <Box>
                <FormLabel>From</FormLabel>
                <StandaloneSearchBox
                    onLoad={ref => originRef.current = ref}
                    onPlacesChanged={() => handlePlaceChange('origin', originRef)}
                >
                    <Input
                        value={places.origin.address}
                        onChange={(e) => {
                            const newAddress = e.target.value;
                            setPlaces({
                                ...places,
                                origin: {
                                    ...places.origin,
                                    address: newAddress,
                                },
                            });
                        }}
                        placeholder="Enter location"
                    />
                </StandaloneSearchBox>
            </Box>
            <Box>
                <FormLabel>To</FormLabel>
                <StandaloneSearchBox
                    onLoad={ref => destinationRef.current = ref}
                    onPlacesChanged={() => handlePlaceChange('destination', destinationRef)}
                >
                    <Input
                        value={places.destination.address}
                        onChange={(e) => {
                            const newAddress = e.target.value;
                            setPlaces({
                                ...places,
                                destination: {
                                    ...places.destination,
                                    address: newAddress,
                                },
                            });
                        }}
                        placeholder="Enter location"
                    />
                </StandaloneSearchBox>
            </Box>
            {(places.origin && places.destination) && (
                <DistanceMatrixService
                    options={{
                        destinations: [places.destination.coordinates],
                        origins: [places.origin.coordinates],
                        travelMode: "DRIVING"
                    }}
                    callback={res => {
                        setDistance(res.rows[0].elements[0].distance.text);
                    }}
                />
            )}
            <Box>
                <Card p="4" borderRadius="20" minW="15rem">
                    <Flex gap="1rem">
                        <Box pos="relative" borderRadius="100%" h="56px" w="56px" bg="dfds.gray.extralight">
                            <AbsoluteCenter>
                                <DistanceIcon />
                            </AbsoluteCenter>
                        </Box>
                        <Stat>
                            <StatLabel>Distance</StatLabel>
                            <StatNumber>{distance || '- km'}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </Box>
        </Flex>
    );
};

export default Distance;
