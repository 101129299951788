import React from "react";
import { Route, Routes } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import Configure from "./Configure";
import Output from "./Output";
import CustomiseMapContainer from "../../../containers/CustomiseMapContainer";
import OutputsContainer from "../../../containers/OutputsContainer";

const MapOutput = props => {
    const routes = [
        { id: 'configure', path: "/configure", element: newProps => <Configure {...newProps} /> },
        { id: 'output', path: "/output", element: newProps => <OutputsContainer {...props}><Output {...newProps} /></OutputsContainer> },
    ];

    return (
        <Flex h="100%" flexDirection="column">
            <Routes>
                {routes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={(
                            <CustomiseMapContainer {...props}>
                                {route.element()}
                            </CustomiseMapContainer>
                        )}
                    />
                ))}
            </Routes>
        </Flex>
    );
};

export default MapOutput;