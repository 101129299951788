import { cloneElement, useEffect, useState } from "react";
import { getLegs as getLegsApi, addLeg as addLegApi, deleteLeg as deleteLegApi, updateLeg as updateLegApi, sortLegs } from "../api";
import { useParams } from "react-router-dom";
import { enrichLegsWithPaths } from "../helpers";
import { useAuth0 } from "@auth0/auth0-react";

const CreateContainer = ({ children }) => {
    const { id } = useParams();
    const { getIdTokenClaims } = useAuth0();
    const [userToken, setUserToken] = useState(null);
    const [legs, setLegs] = useState([]);
    const [searoutes, setSearoutes] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        getIdTokenClaims().then(res => {
            if (!!res && !!res.__raw) {
                setUserToken(res.__raw);
                getLegsApi(id, res.__raw).then(legs => enrichLegsWithPaths(legs, res.__raw)
                    .then(res => {
                        if (res.error) throw res;
                        const { legs, searoutes } = res;
                        setLegs(legs.sort((a, b) => a.sort - b.sort));
                        setSearoutes(searoutes);
                    }))
                    .catch(e => setError(e.error));
            } else {
                setError("Something went wrong");
            }
        });
    }, []);

    const addLeg = leg => addLegApi(id, leg, userToken)
        .then(({ id, ...res }) => {
            if (res.error) {
                throw res;
            }
            setLegs(legs => [...legs, { id, ...leg, sort: legs.length }]);
        })
        .catch(e => {
            if (e.error) {
                setError(e.error);
            } else {
                setError("An unexpected error occurred");
            }
        });

    const deleteLeg = index => deleteLegApi(legs[index].id, userToken)
        .then(res => {
            if (res.error) throw res
            setLegs(legs => {
                const newLegs = [...legs];
                newLegs.splice(index, 1);
                return newLegs;
            });
        })
        .catch(e => setError(e.error));

    const updateLeg = (index, leg) => updateLegApi(leg.id, leg, userToken)
        .then(res => {
            if (res.error) throw res
            setLegs(legs => {
                const newLegs = [...legs];
                return [...newLegs.slice(0, index), leg, ...newLegs.slice(index + 1)];
            });
        })
        .catch(e => setError(e.error));

    const updateSortLegs = legs => {
        sortLegs(id, legs.map((leg, index) => ({ id: leg[0].id, name: leg[0].name, sort: index })), userToken)
            .then(res => {
                if (res.error) throw res;
            })
            .catch(e => setError(e.error));
    }

    const resetError = () => setError(false);

    return cloneElement(children, { error, resetError, legs, addLeg, updateLeg, deleteLeg, searoutes, updateSortLegs, userToken });
}

export default CreateContainer;
