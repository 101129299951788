import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

const Add = ({ text, onClick }) => (
    <Flex gap="1rem" alignItems="center">
        <IconButton
            size="sm"
            isRound={true}
            bgColor="dfds.actionblue"
            icon={<PlusIcon />}
            _hover={{
                background: "dfds.groupblue"
            }}
            onClick={onClick}
        />
        <Text>{text}</Text>
    </Flex>
);

export default Add;