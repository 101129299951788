import React, { memo, useState } from "react";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { ButtonGroup } from "@chakra-ui/react";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../paths";
import LegSlide from "../../layout/slides/LegSlide/LegSlide";
import PrimaryButton from "../../forms/buttons/PrimaryButton/PrimaryButton";
import constants from "../../../constants";
import Tooltip from "../../overlay/Tooltip/Tooltip";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert"
import Heading from "../../typography/Heading/Heading";
import Toggle from "../../forms/Toggle/Toggle";

const Output = ({ leg, settings, saveOutput, passMapNode, directDownloadOutput, error, resetError, updateSettings }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleOnSave = () => {
        let parameters = `${constants.table.strings[leg.reductionType]}: `;
        if (!leg.reductionTable) {
            parameters += 'Without reduction table';
        } else {
            parameters += Object.values(leg.reductionTable.scenarios)
                .filter(scenario => scenario !== '')
                .map(scenario => scenario + (leg.modality === 'truck' ? ' trailers' : '%'))
                .join(', ');
        }
        setIsSaving(true);
        saveOutput(id, {
            type: constants.general.LEG,
            parameters,
            leg,
            route: `${leg.places.origin.address} - ${leg.places.destination.address}`
        }).then(() => setIsSaving(false));
    }

    // Click to download presentation slides
    const handleOnDownload = () => {
        setIsDownloading(true);
        directDownloadOutput({
            type: constants.general.LEG,
            leg,
            route: `${leg.places.origin.address} - ${leg.places.destination.address}`
        }).then(() => setIsDownloading(false));
    };

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            {leg && (
                <LegSlide leg={leg} downloadSettings={settings.downloadSettings} passMapNode={passMapNode} />
            )}
            <Box pos="absolute" top="1rem" right="2rem">
                <Tooltip text="You can zoom in and out of the map until you are happy with the shown view. What you see is what you will then download." />
            </Box>
            <Stack pt="6" pb="6" gap="1rem">
                <Heading text="Download settings" size="md" />
                <Flex gap="2rem">
                    <Toggle
                        isDisabled={leg.modality === 'truck'}
                        leftLabel={constants.general.strings.TRAILER}
                        rightLabel={constants.general.strings.LANEMETERS}
                        isChecked={leg.modality === 'truck' ? false : settings.downloadSettings.isLanemeters}
                        onChange={e => updateSettings({ ...settings, downloadSettings: { ...settings.downloadSettings, isLanemeters: e.target.checked } })}
                    />
                </Flex>
            </Stack>
            <Flex justifyContent="space-between">
                <Box>
                    <BackButton onClick={() => navigate(paths.legSettings.replace(':id', id), { state: { leg } })} />
                </Box>
                <ButtonGroup>
                    <PrimaryButton text="Save" onClick={handleOnSave} isLoading={isSaving} isDisabled={isSaving || isDownloading} />
                    <PrimaryButton text="Download" onClick={handleOnDownload} isLoading={isDownloading} isDisabled={isSaving || isDownloading} />
                </ButtonGroup>
            </Flex>
        </>
    )
}

export default memo(Output);