import { cloneElement, useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { getFlows, addFlow as addFlowApi, deleteFlow as deleteFlowApi } from "../api";

const FlowsContainer = ({ children }) => {
    const { user, getIdTokenClaims } = useAuth0();
    const [flows, setFlows] = useState([]);
    const [error, setError] = useState(false);
    const [userToken, setUserToken] = useState(null);

    useEffect(() => {
        getIdTokenClaims().then(res => {
            if (!!res && !!res.__raw) {
                setUserToken(res.__raw);
                getFlows(res.__raw)
                    .then(res => {
                        if (res.error) throw res;
                        setFlows(res);
                    })
                    .catch(e => setError(e.error));
            };
        });
    }, []);

    const addFlow = (company, name) => {
        if (user && user.sub && userToken) {
            return addFlowApi(company, name, user.sub, userToken)
                .then(res => {
                    if (res.error) throw res;
                    setFlows([...flows, res]);
                    return res.id;
                })
                .catch(e => setError(e.error));
        } else {
            setError("Sorry, we couldn't add the flow for you. Please try again!")
        }
    };

    const deleteFlow = id => deleteFlowApi(id, userToken)
        .then(res => {
            if (res.error) throw res;
            const index = flows.findIndex(flow => flow.id === res.id);
            setFlows([...flows.slice(0, index), ...flows.slice(index + 1)]);
        })
        .catch(e => setError(e.error));

    const resetError = () => setError(false);

    return cloneElement(children, { error, resetError, flows, addFlow, deleteFlow });
};

export default FlowsContainer;