import React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { useAuth0 } from "@auth0/auth0-react";
import { Image } from "@chakra-ui/image";
import LogoSmall from '../../../assets/img/Logo--small.png';
import paths from '../../../paths';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import colors from "../../../styles/colors";


const Sidebar = () => {
    const { user, logout } = useAuth0();
    return (
        <Box minH="100vh" p="6">
            <Flex
                direction="column"
                justifyContent="space-between"
                borderWidth='1px'
                borderRadius="30"
                h="calc(100vh - 2*var(--chakra-space-6))"
                boxShadow="lg"
                bg="white"
            >
                <Box px="2" py="6">
                    <Flex
                        as="a"
                        href={paths.home}
                        justify="center"
                    >
                        <Image src={LogoSmall} alt="DFDS Logo" />
                    </Flex>
                </Box>
                <Box px="4" py="6">
                    {user && (
                        <Popover>
                            <PopoverTrigger>
                                <Image
                                    cursor="pointer"
                                    borderRadius="100%"
                                    src={user.picture}
                                />
                            </PopoverTrigger>
                            <PopoverContent w="100%">
                                <PopoverBody
                                    fontWeight="medium"
                                    color="dfds.primaryorange"
                                    onClick={() => logout()}
                                    cursor="pointer"
                                    _hover={{ color: colors.dfds.primaryorangedark }}
                                >
                                    Log out
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default Sidebar;