import { extendTheme } from "@chakra-ui/theme-utils";
import { switchTheme } from "./components/forms/Toggle/switchTheme";

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "gray.50"
            }
        }
    },
    colors: {
        dfds: {
            50: "#002B45",
            100: "#68B2E3",
            200: "#EFEEEE",
            500: "#002B45",
            600: "#68B2E3",
            groupblue: "#002B45",
            actionblue: "#68B2E3",
            actionbluedark: "#2c93d8",
            primaryorange: "#ED8B00",
            primaryorangedark: "#B57314",
            offwhite: "#FAFCFE",
            purpleblue: "#4318FF",
            black: "#000000",
            lime: "#ecfd6d",
            slideblue: "#2369f5",
            slide: {
                blue: "#2369f5",
                gray: "#9e9e9e",
                beige: "#edebde",
                lime: "#ECFD6D",
            },
            gray: {
                text: "#515153",
                softtext: "#8F9BBA",
                medium: "#B8B8B7",
                bluegray: "#E0E5F2",
                extralight: "#EFEEEE"
            }
        }
    },
    breakpoints: {
        base: '0px',
        sm: '320px',
        md: '768px',
        lg: '960px',
        xl: '1200px'
    },
    components: {
        Switch: switchTheme
    }
});

export default theme;