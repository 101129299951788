import React, { useEffect, useState } from "react";
import { AbsoluteCenter, ButtonGroup, Card, CardBody, Flex, IconButton, Text } from "@chakra-ui/react";
import Table from "../../visualizations/tables/Table/Table";
import colors from "../../../styles/colors";
import { useParams } from "react-router-dom";
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as TrashCanIcon } from '../../../assets/icons/trash-can.svg';
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert"

const OutputsTable = ({ getOutputs, downloadOutput, deleteOutput, error: propsError, resetError: propsResetError }) => {
    const { id } = useParams();
    const [isLoading, setIsloading] = useState(true);
    const [outputs, setOutputs] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        getOutputs(id).then(outputs => {
            if (outputs.error) throw outputs
            setOutputs(outputs);
            setIsloading(false);
        })
            .catch(e => {
                setIsloading(false);
                setError(e.error)

            });
    }, [id]);

    useEffect(() => {
        setError(propsError)
    }, [propsError])

    const handleOnDelete = id => {
        deleteOutput(id).then(res => {
            const newOutputs = [...outputs];
            const index = newOutputs.findIndex(output => output.id === res.id);
            setOutputs([...newOutputs.slice(0, index), ...newOutputs.slice(index + 1)]);
        })
    };

    const resetError = () => {
        propsResetError();
        setError(false);
    }

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            <Card
                h="100%"
                w="100%"
                borderRadius="30px"
                boxShadow={`0px 4px 12px ${colors.dfds.black}1a`}
            >
                <CardBody pos="relative">
                    <Table
                        headers={["Type", "Date created", "Leg", "Parameters", ""]}
                        rows={outputs.map(output => [
                            <b>{output.type}</b>,
                            output.createdAt.split('T')[0],
                            output.leg || 'N/A',
                            output.parameters.split(';')[0],
                            <Flex justifyContent="flex-end">
                                <ButtonGroup>
                                    <IconButton
                                        bg="transparent"
                                        _hover={{ svg: { fill: colors.dfds.groupblue } }}
                                        icon={<DownloadIcon width={18} height={18} fill={colors.dfds.actionblue} />}
                                        onClick={() => downloadOutput(output.id)}
                                    />
                                    <IconButton
                                        bg="transparent"
                                        _hover={{ svg: { fill: colors.dfds.groupblue } }}
                                        icon={<TrashCanIcon width={18} heights={18} fill={colors.dfds.primaryorange} />}
                                        onClick={() => handleOnDelete(output.id)}
                                    />
                                </ButtonGroup>
                            </Flex>
                        ])}
                    />
                    {outputs.length === 0 && !isLoading && (
                        <AbsoluteCenter top="60%">
                            <Text color="dfds.gray.medium">You have no saved outputs</Text>
                        </AbsoluteCenter>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default OutputsTable;