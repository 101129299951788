import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { GoogleMap, MarkerF, OverlayView, Polyline } from "@react-google-maps/api";
import BlueDot from '../../../assets/icons/dot.svg';
import { checkEqualArrays, getColor, getMarkers } from "../../../helpers";
import { Box } from "@chakra-ui/react";

const MapViz = ({ mapId, legs: propsLegs, passMapNode }) => {
    const ref = useRef();
    const [legs, setLegs] = useState([]);

    useEffect(() => {
        if (!checkEqualArrays(propsLegs, legs)) {
            setLegs(propsLegs);
        }
    }, [propsLegs]);

    const handleOnLoad = map => {
        ref.current = map;
        passMapNode(map);
    }

    const blueDotIcon = {
        url: BlueDot,
        scaledSize: new window.google.maps.Size(10, 10),
        anchor: new window.google.maps.Point(5, 5)
    };

    return legs.length > 0 && (
        <GoogleMap
            ref={ref}
            key={mapId}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={{ lat: 53.708870, lng: 11.974560 }}
            zoom={3}
            onLoad={handleOnLoad}
            options={{ mapId: process.env.REACT_APP_MAP_ID, disableDefaultUI: true }}
        >
            {getMarkers(legs).map(marker => (
                <Fragment key={marker}>
                    <MarkerF
                        key={`marker-${marker.address}`}
                        position={marker.coordinates}
                        icon={blueDotIcon}
                    />
                    <OverlayView
                        position={marker.coordinates}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <Box
                            id={`marker-${marker.address}`}
                            display="inline-block"
                            p="0.5"
                            fontWeight="bold"
                        >
                            {marker.address}
                        </Box>
                    </OverlayView>
                </Fragment>
            ))}
            {legs.map(leg => (
                <Polyline
                    key={`polyline-${leg.id}`}
                    path={leg.places.path}
                    options={{ strokeColor: getColor(leg) }}
                />
            ))}
        </GoogleMap>
    );
};

export default memo(MapViz);