import React from "react"
import Button from ".."

const SecondaryButton = ({ onClick, text, isDisabled }) => (
	<Button
		isDisabled={isDisabled}
		text={text}
		onClick={onClick}
		border="2px solid"
		borderColor="dfds.groupblue"
		bg="transparent"
	/>
);

export default SecondaryButton;