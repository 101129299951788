import React from "react";
import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Configure from "./Configure";
import Output from "./Output";
import CustomiseLegContainer from "../../../containers/CustomiseLegContainer";
import OutputsContainer from '../../../containers/OutputsContainer';

const LegOutput = (props) => {
    const routes = [
        { id: 'configure', path: "/configure", element: newProps => <Configure {...newProps} /> },
        { id: 'output', path: "/output", element: newProps => <OutputsContainer {...props}><Output {...newProps} /></OutputsContainer> },
    ];

    return (
        <Flex h="100%" flexDirection="column">
            <Routes>
                {routes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={(
                            <CustomiseLegContainer {...props} step={route.id}>
                                {route.element()}
                            </CustomiseLegContainer>
                        )}
                    />
                ))}
            </Routes>
        </Flex>
    )
};

export default LegOutput;