import React from "react";
import Alert from "..";

const ErrorAlert = ({ error, onClick }) => (
    <Alert
        isOpen={true}
        title="Error"
        body={error}
        primaryButtonText="OK"
        primaryButtonOnClick={onClick}
    />
);

export default ErrorAlert;