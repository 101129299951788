import React from "react";
import { Box, Input, InputGroup } from "@chakra-ui/react";
import colors from "../../../styles/colors";

const InputAddon = ({ value, onChange, isDisabled, text }) => (
    <InputGroup pos="relative" maxW="25rem">
        <Input
            size="sm"
            isDisabled={isDisabled}
            type="number"
            placeholder="Reduction"
            pr="2rem"
            border="none"
            borderRadius={0}
            borderBottom={`1px solid ${colors.dfds.groupblue}`}
            focusBorderColor="dfds.actionblue"
            value={value}
            onChange={onChange}
        />
        <Box pos="absolute" right="1rem" top="50%" transform="translateY(-50%)" opacity={isDisabled ? 0.4 : 1}>{text}</Box>
    </InputGroup>
);

export default InputAddon;