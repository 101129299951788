import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import constants from "../../../constants";

const MapLegendItem = ({ bg, label }) => (
    <Flex gap={2} alignItems="center">
        <Box bg={bg} h={1} w={7} borderRadius={10}></Box>
        <Box><Text fontSize={11}>{label}</Text></Box>
    </Flex>
)

const MapLegend = ({ legendsCondition }) => (
    <Box
        pos="absolute"
        left={1}
        top={1}
        py={1}
        px={2}
        bg="white"
        borderRadius={5}
    >
        {Object.values(legendsCondition).map(legend => legend.visible && <MapLegendItem bg={legend.color} label={legend.text} />)}
    </Box>
);

export default MapLegend;