import { getSearoutes } from "./api";
import { formatFloat } from "./calculations";
import constants from "./constants";

export const enrichLegsWithPaths = async (legs, token) => {
    try {
        const searoutes = await getSearoutes(token);
        if (searoutes.error) throw searoutes;
        return {
            searoutes,
            legs: legs.map(leg => ({
                ...leg,
                places: {
                    ...leg.places,
                    path: leg.modality === 'ship' ?
                        searoutes.find(route => route.name === leg.places.route).path :
                        [leg.places.origin.coordinates, leg.places.destination.coordinates]
                }
            }))
        }
    } catch { return { error: "Sorry could not get all the info" } };
};

export const createMapUrl = (mapRef, legs, size) => {
    const center = mapRef.getCenter().toJSON();
    const zoom = mapRef.getZoom();
    let staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?scale=2&center=${center.lat},${center.lng}&zoom=${zoom}&size=${size.width}x${size.height}&key=${process.env.REACT_APP_MAP_API_KEY}&map_id=${process.env.REACT_APP_MAP_ID}`;
    const markers = legs.map(leg => `${leg.places.origin.coordinates.lat},${leg.places.origin.coordinates.lng}|${leg.places.destination.coordinates.lat},${leg.places.destination.coordinates.lng}`)
    const polylines = legs.map(leg => '&path=color:' + getHexConvertColor(leg) + '|weight:3|' + leg.places.path.map(coord => `${coord.lat},${coord.lng}`).join('|')).join('');
    const markerUrl = 'https://lh3.googleusercontent.com/pw/ADCreHdtutn6Emme36SBUHQx2kgtkra5edvVJObbgO21hGBFmKt7mFe1pLKScU2EBPDlB4yuryDToV4hBLJrWJ6brJIqjHzAJPEroIPmgQZ_NaVLCu_GGxGVPH-4yAjSpFRitAW_SQX7zt4d2JbyrsMxQi3x=w10-h10-s-no-gm?authuser=0';
    staticMapUrl += `&markers=icon:${markerUrl}|anchor:5,5|` + markers.join('|') + polylines;
    return staticMapUrl;
};

export const getColor = leg => {
    if (leg.fuelType || leg.reductionType) {
        return constants.map.colors[leg.modality][leg.fuelType || leg.reductionType]
    } else {
        return constants.map.colors[leg.modality].DEFAULT_FUEL
    }
}

export const legendsCondition = legs => legs.reduce((prev, curr) => {
    if (curr.modality === 'ship') {
        if (curr.fuelType === constants.table.BIOFUEL || curr.reductionType === constants.table.BIOFUEL) {
            return { ...prev, shipBiofuel: { ...prev.shipBiofuel, visible: true } }
        } else {
            return { ...prev, shipDefault: { ...prev.shipDefault, visible: true } }
        }
    }
    if (curr.fuelType === constants.table.ELECTRICITY || curr.reductionType === constants.table.ELECTRICITY) return { ...prev, truckElectric: { ...prev.truckElectric, visible: true } };
    return { ...prev, truckDefault: { ...prev.truckDefault, visible: true } }
}, {
    truckDefault: { visible: false, text: `Truck – ${constants.table.strings.DIESEL}`, color: constants.map.colors.truck.DEFAULT_FUEL, colorName: 'groupBlue' },
    truckElectric: { visible: false, text: `Truck – ${constants.table.strings.ELECTRICITY}`, color: constants.map.colors.truck.ELECTRICITY, colorName: 'actionBlue' },
    shipDefault: { visible: false, text: `Ship – ${constants.table.strings.MGO}`, color: constants.map.colors.ship.DEFAULT_FUEL, colorName: 'primaryOrangeDark' },
    shipBiofuel: { visible: false, text: `Ship – ${constants.table.strings.BIOFUEL}`, color: constants.map.colors.ship.BIOFUEL, colorName: 'primaryOrange' }
})

export const getHexConvertColor = leg => {
    const color = getColor(leg);
    return `0x${color.split('#')[1]}ff`;
}

export const checkEqualArrays = (arrA, arrB) => {
    if (arrA.length !== arrB.length) return false;

    for (let i = 0; i < arrA.length; i++) {
        if (arrA[i].id !== arrB[i].id) return false;
    }
    return true;
};

export const formatNumber = number => {
    const strNumber = String(formatFloat(number));
    const [integer, decimal] = strNumber.split('.');

    const formattedInteger = `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

    return decimal ? `${formattedInteger},${decimal}` : formattedInteger;
};

export const getMarkers = legs => Object.values(legs.reduce((prev, curr) => ({ ...prev, [curr.places.origin.address]: { address: curr.places.origin.address, coordinates: curr.places.origin.coordinates }, [curr.places.destination.address]: { address: curr.places.destination.address, coordinates: curr.places.destination.coordinates } }), {}));

export const getPositionsInSlide = legs => {
    let positions = [];
    let slideContainer = document.getElementById('slides-wrapper');
    const slide = slideContainer.getBoundingClientRect();
    const ratio = slide.height / constants.slides.size.height;
    const offset = 4;
    const markers = getMarkers(legs);
    for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];
        let dot = document.getElementById(`marker-${marker.address}`);

        const dotBounding = dot.getBoundingClientRect();

        positions = [
            ...positions,
            {
                top: (dotBounding.top - slide.top) / ratio - offset,
                left: (dotBounding.left - slide.left) / ratio - offset,
                name: marker.address
            }
        ]
    }
    return positions;
};