import { Box, Checkbox } from "@chakra-ui/react";
import React from "react";

const Include = ({ text, isDisabled, isChecked, onChange }) => (
    <Box>
        <Checkbox
            colorScheme="dfds"
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={onChange}
        >
            {text}
        </Checkbox>
    </Box>
);

export default Include;