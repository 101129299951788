import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const InfoSection = ({ legNumber, borderBottom, strings }) => (
    <Flex gap="1rem" py="3" mx="6" borderBottom={borderBottom && "2px solid"} borderColor="dfds.slide.gray">
        <Box>
            <Heading fontSize={20} color="dfds.lime">{legNumber}</Heading>
        </Box>
        <Box>
            <Text color="white" fontSize={11} fontWeight="bold">{strings.title}</Text>
            {strings.text.map(t => <Text fontSize={9} color="white">{t}</Text>)}
        </Box>
    </Flex>
);

export default InfoSection;