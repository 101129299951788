import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import Heading from "../../typography/Heading/Heading";
import Table from "../../visualizations/tables/Table/Table";
import InputAddon from "../../forms/InputAddon/InputAddon";
import Include from "../../forms/Include/Include";

const ConfigureSettingsReductionLeg = ({ table, updateTable }) => (
    <Stack gap="1rem">
        <Heading size="sm" text="Table 2: Reduction settings" textTransform="uppercase" />
        <Text>
            Create up to 3 reduction scenarios by entering the desired reduction sizes in % below.
        </Text>
        <Table
            headers={["Scenario", "Reduction"]}
            rows={[
                ...Object.keys(table.scenarios).map(key => ([
                    "Scenario " + key,
                    <InputAddon
                        text={table.modality === 'truck' ? `of ${table.trailers} trailers` : '%'}
                        value={table.scenarios[key] || ''}
                        onChange={e => updateTable({
                            ...table,
                            scenarios: {
                                ...table.scenarios,
                                [key]: e.target.value
                            }
                        })}
                    />
                ]))
            ]}
        />
        <Include
            text="Show size of reduction (t CO₂e)"
            isChecked={table.showReduction}
            onChange={e => updateTable({ ...table, showReduction: e.target.checked })}
        />
        <Include
            text="Show emissions after reduction (t CO₂e)"
            isChecked={table.showRest}
            onChange={e => updateTable({ ...table, showRest: e.target.checked })}
        />
        <Include
            text="Show total price"
            isChecked={table.showTotalPrice}
            onChange={e => updateTable({ ...table, showTotalPrice: e.target.checked })}
        />
    </Stack>
);

export default ConfigureSettingsReductionLeg;