import { Flex, Switch, Text } from "@chakra-ui/react";
import React from "react";

const Toggle = ({ leftLabel, rightLabel, isChecked, onChange, isDisabled }) => (
    <Flex gap="1rem">
        <Text fontSize="sm" fontWeight="medium" color={isChecked ? 'dfds.gray.softtext' : 'dfds.gray.text'}>{leftLabel}</Text>
        <Switch
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={onChange}
            opacity={isChecked !== null ? 1 : 0.4}
        />
        <Text fontSize="sm" fontWeight="medium" color={isChecked ? 'dfds.gray.text' : 'dfds.gray.softtext'}>{rightLabel}</Text>
    </Flex>
);

export default Toggle;