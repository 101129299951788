import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import Header from "../Header/Header";
import Sidebar from "../../nav/Sidebar/Sidebar";

const Site = ({ main, withoutHeader }) => (
    <Grid
        templateAreas={
            `"nav header"
        "nav main"`
        }
        gridTemplateColumns="138px 1fr"
        gridTemplateRows="auto 1fr"
    >
        <GridItem area="nav">
            <Sidebar />
        </GridItem>
        <GridItem area="header" p="6">
            {!withoutHeader && <Header />}
        </GridItem>
        <GridItem area="main" px="6" mr="6" mb="6" w="100%" overflowY="scroll">
            {main}
        </GridItem>
    </Grid>
);

export default Site;