import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import constants, { getColumnWidth, tableRow, tableSlideHeaders, legSlideHeaders, totalRow, legRow } from "../../../../constants";
import colors from "../../../../styles/colors";

const TableSlide = ({ table, type, borderColor, isLanemeters, hasBorderBottom, withHighlight, color }) => {
    let headers;
    let headerSize, padding, highlightedIndex;

    switch (true) {
        case type === constants.leg.LEG:
            headers = legSlideHeaders();
            headerSize = getColumnWidth(type);
            break;
        default: {
            headers = tableSlideHeaders();
            highlightedIndex = 3;
            padding = 2;
            headerSize = getColumnWidth(constants.table.SEVEN_COLUMNS);
        }
    }

    const tHeader = (pos, isHighlighted) => (
        <GridItem key={headers[pos]}>
            <Flex borderBottom={`1px solid ${borderColor || colors.dfds.slide.gray}`} color={isHighlighted ? "white" : color || 'black'} bg={isHighlighted ? 'dfds.slide.blue' : 'transparent'} alignItems="flex-end" h="100%" px={padding} py={1} fontSize={9} fontWeight="bold">{headers[pos]}</Flex>
        </GridItem>
    );

    return (
        <Box w="100%">
            <Grid templateColumns={`${headerSize.join(' ')}`}>
                {headerSize.map((header, index) => tHeader(index, withHighlight && index === highlightedIndex))}
                {table.legs.map((leg, rowIndex) => {
                    const row = (type === constants.leg.LEG) ? legRow(leg, isLanemeters) : tableRow(leg, isLanemeters);
                    return row.map((column, index) => (
                        <GridItem key={column}>
                            <Flex borderBottom={(!!hasBorderBottom && rowIndex === table.legs.length - 1) ? `1px solid ${borderColor || colors.dfds.slide.gray}` : ''} h="100%" fontSize={9} px={padding} py={1} alignItems="center" color={highlightedIndex === index ? 'white' : color || 'black'} bg={withHighlight && highlightedIndex === index ? 'dfds.slide.blue' : 'transparent'}>{column}</Flex>
                        </GridItem>
                    ));
                })}
                {(type !== constants.leg.LEG) && totalRow(table.legs, isLanemeters).map((column, index) => (
                    <GridItem key={column}>
                        <Flex borderBottom={`1px solid ${borderColor || colors.dfds.slide.gray}`} h="100%" fontSize={9} px={padding} py={1} alignItems="center" color={highlightedIndex === index ? 'white' : color || 'black'} bg={withHighlight && highlightedIndex === index ? 'dfds.slide.blue' : 'transparent'}>{column}</Flex>
                    </GridItem>
                ))}
            </Grid>
        </Box>
    )
};

export default TableSlide;