import React from "react";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import colors from "../../../styles/colors";
import { ReactComponent as TrashCanIcon } from '../../../assets/icons/trash-can.svg';

const Remove = ({ onClick }) => (
    <Flex alignItems="center">
        <Text fontWeight="medium">Delete table</Text>
        <IconButton
            size="sm"
            isRound={true}
            bg="transparent"
            icon={<TrashCanIcon fill={colors.dfds.actionblue} />}
            _hover={{
                svg: { fill: "dfds.groupblue" }
            }}
            onClick={onClick}
        />
    </Flex>
)

export default Remove;