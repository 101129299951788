import React from "react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, ButtonGroup } from "@chakra-ui/react";
import PrimaryButton from "../../forms/buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../forms/buttons/SecondaryButton/SecondaryButton";

const Alert = ({ isOpen, title, body, primaryButtonText, secondaryButtonText, primaryButtonOnClick, secondaryButtonOnClick, primaryButtonIsDisabled }) => (
    <AlertDialog
        isOpen={isOpen}
        isCentered
        onClose={secondaryButtonOnClick}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader>
                    {title}
                </AlertDialogHeader>
                <AlertDialogBody>
                    {body}
                </AlertDialogBody>
                <AlertDialogFooter justifyContent="center">
                    <ButtonGroup>
                        <PrimaryButton isDisabled={primaryButtonIsDisabled} text={primaryButtonText} onClick={primaryButtonOnClick} />
                        {!!secondaryButtonText && <SecondaryButton text={secondaryButtonText} onClick={secondaryButtonOnClick} />}
                    </ButtonGroup>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
);

export default Alert;