import React from "react";
import { Heading as ChakraHeading } from "@chakra-ui/react";


const Heading = ({ size, text, align, }) => {
	switch (size) {
		case "xl":
			return <ChakraHeading as="h1" color="dfds.groupblue" size={size} textAlign={align}>{text}</ChakraHeading>;
		case "md":
			return <ChakraHeading as="h2" color="dfds.groupblue" size={size} textAlign={align}>{text}</ChakraHeading>;
		case "sm":
			return <ChakraHeading as="h3" fontWeight="medium" color="dfds.gray.medium" textTransform="uppercase" size={size} textAlign={align}>{text}</ChakraHeading>;
		default:
			return null;
	}
}

export default Heading;