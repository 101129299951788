import { Route, Routes, } from 'react-router';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { useAuth0 } from "@auth0/auth0-react";
import Home from './components/pages/Home/Home';
import Create from './components/pages/Create';
import theme from './theme';
import paths from './paths';
import FlowsContainer from './containers/FlowsContainer';
import Site from './components/layout/Site/Site';
import { Guard } from './components/auth/Guard/Guard';

const routes = [
  {
    main: () => <FlowsContainer><Home /></FlowsContainer>,
    name: 'Home',
    path: paths.home
  },
  {
    main: () => <Create />,
    name: 'Create',
    path: paths.create
  }
];

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <ChakraProvider theme={theme}>
        <Site main={(
          <Flex h="100%" alignItems="center" justifyContent="center">
            <Box>Loading...</Box>
          </Flex>
        )} withoutHeader />
      </ChakraProvider>
    )
  };

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        {routes.map(route => (
          <Route
            key={route.name}
            path={route.path}
            element={(
              <Guard component={() => <Site main={route.main()} />} />
            )}
          />
        ))}
      </Routes>
    </ChakraProvider>
  );
};

export default App;
