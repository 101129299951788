import { shipReductions, truckEmissions, truckReductions } from "./calculations";
import { formatNumber } from "./helpers";
import colors from "./styles/colors";

let constants = {
    general: {
        MAP: 'MAP',
        TABLE: 'TABLE',
        LEG: 'LEG',
        TTW: "TTW",
        WTW: "WTW",
        AMBIENT: "AMBIENT",
        TEMPERATURE_CONTROLLED: "TEMPERATURE_CONTROLLED",
        TRAILER: "TRAILER",
        TONNE: "TONNE",
        LANEMETERS: "LANEMETERS",
        strings: {
            TTW: "TTW",
            WTW: "WTW",
            AMBIENT: "Ambient",
            TEMPERATURE_CONTROLLED: "Temperature controlled",
            TN: 'Tn',
            KG: 'Kg',
            TRAILER: "Trailer",
            LANEMETERS: "Lanemeter",
        },
        factors: {
            KG_TO_TONNE: 0.001,
            TONNE_TO_KG: 1000,
            WTW: 1.08,
            TTW: 1,
            AMBIENT: 0.086,
            TEMPERATURE_CONTROLLED: 0.096,
            TRAILER: 1,
            LANEMETERS: 14,
            CONSUMPTION: 1.6,
            DIESEL_EQ: 0.1008,
            ENGINE_EF: 2.125,
            WTW_DIESEL: 3.17,
            TTW_DIESEL: 2.54,
            DELTA_EMISSIONS: 0.2
        }
    },
    slides: {
        size: { width: 720, height: 405 },
        padding: { x: 16 },
        leg: {
            content: { width: 420 },
            map: { width: 300 }
        }
    },
    map: {
        DEFAULT_CO2E_EMISSIONS: 'DEFAULT_CO2E_EMISSIONS',
        REDUCED_CO2E_SCENEARIO: 'REDUCED_CO2E_SCENEARIO',
        BOTH_SCENEARIOS: 'BOTH_SCENEARIOS',
        SAME_MAP: 'SAME_MAP',
        SEPERATE_MAPS: 'SEPERATE_MAPS',
        DEFAULT_FUEL: 'DEFAULT_FUEL',
        strings: {
            DEFAULT_CO2E_EMISSIONS: 'Default CO₂e',
            REDUCED_CO2E_SCENEARIO: 'Reduced CO₂e scenario',
            BOTH_SCENEARIOS: 'Both default CO₂e and reduced CO₂e',
            SAME_MAP: 'Same map',
            SEPERATE_MAPS: 'Seperate maps',
            DEFAULT_FUEL: 'Default fuel'
        },
        colors: {
            ship: {
                DEFAULT_FUEL: colors.dfds.primaryorangedark,
                BIOFUEL: colors.dfds.primaryorange
            },
            truck: {
                DEFAULT_FUEL:  colors.dfds.groupblue,
                ELECTRICITY: colors.dfds.actionblue
            }
        }
    },
    table: {
        NOT_INCLUDE: 'NOT_INCLUDE',
        LNG: 'LNG',
        DIESEL: 'DIESEL',
        ELECTRICITY: 'ELECTRICITY',
        HVO100: 'HVO100',
        MGO: 'MGO',
        HFO: 'HFO',
        ULSFO: 'ULSFO',
        FAME: 'FAME',
        POME: 'POME',
        BIOFUEL: 'BIOFUEL',
        FIVE_COLUMNS: 'FIVE_COLUMNS',
        SEVEN_COLUMNS: 'SEVEN_COLUMNS',
        strings: {
            LNG: 'LNG',
            DIESEL: 'Diesel',
            ELECTRICITY: 'Electricity',
            HVO100: 'HVO100',
            DIRECT: 'Direct',
            INDIRECT: 'Indirect',
            HVO: 'HVO',
            E_TRUCK: 'e-Truck',
            MGO: 'MGO',
            HFO: 'HFO',
            ULSFO: 'ULSFO',
            FAME: 'FAME',
            POME: 'POME',
            BIOFUEL: 'Biofuel',
            NOT_INCLUDE: 'Do not include'
        }
    },
    leg: {
        FIVE_COLUMNS: 'FIVE_COLUMNS',
        SEVEN_COLUMNS: 'SEVEN_COLUMNS',
        LEG: 'LEG'
    }
};

export const reductionOptions = {
    ship: {
        [constants.table.BIOFUEL]: constants.table.strings.BIOFUEL
    },
    truck: {
        [constants.table.ELECTRICITY]: constants.table.strings.ELECTRICITY,
    }
}

export const mapSlideHeadlines = (option, totalEmission, totalReduction, isLanemeters) => {
    const unit = isLanemeters ? 'lanemeter' : 'trailer';
    let headlineEmission = ':ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    headlineEmission = headlineEmission.replace(':ttw', formatNumber(totalEmission.TTW[unit])).replace(':wtw', formatNumber(totalEmission.WTW[unit]))
    let headlineRest = ':ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    headlineRest = headlineRest.replace(':ttw', formatNumber(totalEmission.TTW[unit] - totalReduction.TTW[unit])).replace(':wtw', formatNumber(totalEmission.WTW[unit] - totalReduction.WTW[unit]));
    switch (option) {
        case constants.map.DEFAULT_CO2E_EMISSIONS:
            return [{ bold: 'Total Emissions: ', normal: headlineEmission }];
        case constants.map.REDUCED_CO2E_SCENEARIO:
        case constants.map.BOTH_SCENEARIOS:
            return [{ bold: 'Default emissions: ', normal: headlineEmission }, { bold: 'After reductions: ', normal: headlineRest }];
        default:
            return [];
    }
}

export const mapSlideStrings = (option, leg, isLanemeters) => {
    let title = ':from - :to | :number :type';
    let emissionTrailer = 'Emissions/:unit: :ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    let emissionLeg = 'Emissions/leg: :ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    let afterReduction = 'After :percent% reduction: :ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    let defaultEmission = 'Default emissions: :ttw t CO₂e TTW (:wtw t CO₂e WTW)';
    let reductionFuelType = 'Reduction fuel type: :fuelType' + (leg.modality === 'truck' ? ' (:number trailers)' : '');
    let defaultFuelType = 'Fuel type: :fuelType';
    let emission = 'Emissions: :ttw t CO₂e TTW (:wtw t CO₂e WTW)';

    const number = (leg.modality === 'ship' && isLanemeters) ? (leg.lanemeters || leg.trailers * constants.general.factors.LANEMETERS) : (leg.trailers || Math.ceil(leg.lanemeters / constants.general.factors.LANEMETERS));
    const unit = (leg.modality === 'ship' && isLanemeters) ? 'lanemeter' : 'trailer';

    // with input
    title = title.replace(':from', leg.places.origin.address)
        .replace(':to', leg.places.destination.address)
        .replace(':number', formatNumber(number))
        .replace(':type', (leg.modality === 'ship' && isLanemeters) ? 'Lanemeters' : 'Trailers');
    emissionTrailer = emissionTrailer.replace(':unit', unit).replace(':ttw', formatNumber(leg.emission.unit.TTW[unit]))
        .replace(':wtw', formatNumber(leg.emission.unit.WTW[unit]));

    let textTotal;
    if (option === constants.map.BOTH_SCENEARIOS) {
        textTotal = defaultEmission.replace(':ttw', formatNumber(leg.emission.leg.TTW[unit])).replace(':wtw', formatNumber(leg.emission.leg.WTW[unit]))
    } else {
        textTotal = emissionLeg.replace(':ttw', formatNumber(leg.emission.leg.TTW[unit])).replace(':wtw', formatNumber(leg.emission.leg.WTW[unit]));
    }
    afterReduction = afterReduction.replace(':percent', formatNumber(leg.reduction?.percent)).replace(':ttw', formatNumber(leg.emission.leg["TTW"][unit] - (leg.reduction?.tonne.leg["TTW"][unit] || 0))).replace(':wtw', formatNumber(leg.emission.leg["WTW"][unit] - (leg.reduction?.tonne.leg["WTW"][unit] || 0)));
    emission = emission.replace(':ttw', formatNumber(leg.emission.leg.TTW[unit])).replace(':wtw', formatNumber(leg.emission.leg.WTW[unit]))
    const textRest = leg.reduction?.percent ? afterReduction : emission;
    reductionFuelType = reductionOptions[leg.modality][leg.fuelType] ? reductionFuelType.replace(':fuelType', reductionOptions[leg.modality][leg.fuelType]).replace(':number', leg.reduction.number) : defaultFuelType.replace(':fuelType', leg.modality === 'ship' ? 'MGO' : 'Diesel');

    let text;
    switch (option) {
        case constants.map.DEFAULT_CO2E_EMISSIONS:
            text = [emissionTrailer, textTotal];
            break;
        case constants.map.REDUCED_CO2E_SCENEARIO:
            text = [textRest, reductionFuelType];
            break;
        case constants.map.BOTH_SCENEARIOS:
            text = [textTotal, ...(leg.reduction?.percent ? [textRest] : []), reductionFuelType];
            break;
        default:
            text = [];
    }
    return {
        title,
        text
    }
}

export const tableSlideHeaders = () => {
    const legs = 'Legs',
        unitReduced = 'Units reduced',
        emission = 'Emissions/ unit (t CO₂e)',
        fuelType = 'Reduction fuel type',
        priceReduction = 'Price/t CO₂e reduction',
        priceUnit = 'Price/ unit reduced',
        total = 'Total reduction price';

    return [legs, unitReduced, emission, fuelType, priceReduction, priceUnit, total];
};

export const legSlideHeaders = () => {
    const leg = 'Leg',
        units = 'Units',
        emission = 'Emissions/ unit (t CO₂e)',
        fuelType = 'Reduction fuel type',
        priceReduction = 'Price/t CO₂e reduction',
        priceUnit = 'Price/ unit reduced';

    return [leg, units, emission, fuelType, priceReduction, priceUnit];
};

const getPrice = (leg, isLanemeters) => {
    switch (true) {
        // price unit is per trailer (Truck only)
        case leg.modality === 'truck' && leg.priceUnit === constants.general.TRAILER:
            return {
                WTW: {
                    unitPrice: leg.reductionPrice,
                    legPrice: leg.reductionPrice * leg.reduction?.number
                }
            }
        // price unit is per tonne
        default:
            let numberUnits = leg.modality === 'truck' ? leg.reduction?.number : isLanemeters ? leg.lanemeters || leg.trailers * constants.general.factors.LANEMETERS : (leg.trailers || Math.ceil(leg.lanemeters / constants.general.factors.LANEMETERS));
            if(leg.modality === 'ship') numberUnits = numberUnits * parseInt(leg.reduction?.percent) / 100;
            const unit = leg.modality === 'ship' && isLanemeters ? 'lanemeter' : 'trailer';
            return {
                WTW: {
                    unitPrice: leg.reductionPrice * leg.emission.unit.WTW[unit],
                    legPrice: leg.reductionPrice * leg.emission.unit.WTW[unit] * numberUnits
                },
                TTW: {
                    unitPrice: leg.reductionPrice * leg.emission.unit.TTW[unit],
                    legPrice: leg.reductionPrice * leg.emission.unit.TTW[unit] * numberUnits
                }
            }
    };
}

const strings = {
    route: ':from - :to',
    emission: ':ttw TTW \n(:wtw WTW)'
};
const calcPrice = (leg, isLanemeters) => {
    switch (true) {
        // price unit is per trailer (Truck only)
        case leg.modality === 'truck' && leg.priceUnit === constants.general.TRAILER: {
            let tonne = (leg.trailerPrice && '€:price') || '-';
            let priceString = '€:price';
            const price = getPrice(leg, isLanemeters);

            const deltaPrice = parseFloat(leg.reductionPrice) - parseFloat(leg.trailerPrice);
            const deltaEmission = 1 / constants.general.factors.DELTA_EMISSIONS;
            return {
                tonne: tonne.replace(':price', formatNumber(deltaPrice * deltaEmission)),
                trailer: priceString.replace(':price', formatNumber(price.WTW.unitPrice)),
                legPrice: priceString.replace(':price', formatNumber(price.WTW.legPrice))
            };
        }
        case leg.modality === 'truck' && leg.priceUnit === constants.general.TONNE: {
            let tonne = (leg.trailerPrice && '€:price') || '-';
            let priceString = '€:price';
            const price = getPrice(leg, isLanemeters);

            const deltaPrice = parseFloat(leg.reductionPrice) * constants.general.factors.DELTA_EMISSIONS;

            return {
                tonne: tonne.replace(':price', formatNumber(deltaPrice + parseFloat(leg.trailerPrice))),
                trailer: priceString.replace(':price', formatNumber(price.WTW.unitPrice)),
                legPrice: priceString.replace(':price', formatNumber(price.WTW.legPrice))
            };
        }
        // price unit is per tonne
        default: {
            let tonne = (leg.reductionPrice && '€:price') || '-';
            let priceString = (leg.reductionPrice && '€:ttwprice TTW \n(€:wtwprice WTW)') || '-';
            const price = getPrice(leg, isLanemeters);
            return {
                tonne: tonne.replace(':price', formatNumber(leg.reductionPrice)),
                trailer: priceString
                    .replace(':ttwprice', formatNumber(price.TTW.unitPrice))
                    .replace(':wtwprice', formatNumber(price.WTW.unitPrice)),
                legPrice: priceString
                    .replace(':ttwprice', formatNumber(price.TTW.legPrice))
                    .replace(':wtwprice', formatNumber(price.WTW.legPrice)),
            };
        }
    }
};
export const tableRow = (leg, isLanemeters) => {
    let { route, emission } = strings;
    route = route.replace(':from', leg.places.origin.address).replace(':to', leg.places.destination.address);
    let unitReduced = leg.modality === 'truck' ? ':reduced of :number trailers' : ':reduced% of :number :unitType';
    unitReduced = leg.modality === 'truck' ?
        unitReduced.replace(':reduced', (leg.reduction?.number && formatNumber(leg.reduction?.number)) || 0).replace(':number', formatNumber(leg.trailers)) :
        unitReduced.replace(':reduced', leg.reduction?.percent || 0).replace(':number', isLanemeters ? leg.lanemeters || leg.trailers * constants.general.factors.LANEMETERS : (leg.trailers || Math.ceil(leg.lanemeters / constants.general.factors.LANEMETERS))).replace(':unitType', isLanemeters ? 'lanemeters' : 'trailers');

    let TRL = emission.replace(':ttw', formatNumber(leg.emission.unit.TTW.trailer)).replace(':wtw', formatNumber(leg.emission.unit.WTW.trailer));
    let LM = emission.replace(':ttw', formatNumber(leg.emission.unit.TTW.lanemeter)).replace(':wtw', formatNumber(leg.emission.unit.WTW.lanemeter));
    let fuelType = constants.table.strings[leg.reductionType];
    const { tonne, trailer, legPrice } = calcPrice(leg, isLanemeters);
    let priceTonne = tonne;
    let priceTrailer = trailer;
    let totalPrice = legPrice;
    return [route, unitReduced, leg.modality === 'ship' && isLanemeters ? LM : TRL, fuelType, priceTonne, priceTrailer, totalPrice];
}

export const legRow = (leg, isLanemeters) => {
    let { route, emission } = strings;
    route = route.replace(':from', leg.places.origin.address).replace(':to', leg.places.destination.address);
    let units = leg.modality === 'ship' && isLanemeters ? 'Lanemeters' : 'Trailers';
    let TRL = emission.replace(':ttw', formatNumber(leg.emission.unit.TTW.trailer)).replace(':wtw', formatNumber(leg.emission.unit.WTW.trailer));
    let LM = emission.replace(':ttw', formatNumber(leg.emission.unit.TTW.lanemeter)).replace(':wtw', formatNumber(leg.emission.unit.WTW.lanemeter));
    let fuelType = constants.table.strings[leg.reductionType];
    const { tonne, trailer } = calcPrice(leg, isLanemeters);
    let priceTonne = tonne;
    let priceTrailer = trailer;
    return [route, units, leg.modality === 'ship' && isLanemeters ? LM : TRL, fuelType, priceTonne, priceTrailer];
};

export const totalRow = (legs, isLanemeters) => {
    const label = 'All';
    let reductionPrice = '€:ttw TTW \n(€:wtw WTW)';
    let price = { WTW: 0, TTW: 0 };
    for (let i = 0; i < legs.length; i++) {
        const leg = getPrice(legs[i], isLanemeters);
        price = {
            TTW: price.TTW + (leg.TTW?.legPrice || leg.WTW.legPrice || 0),
            WTW: price.WTW + (leg.WTW.legPrice || 0)
        }
    }
    return [label, ...new Array(5), reductionPrice.replace(':ttw', formatNumber(price.TTW)).replace(':wtw', formatNumber(price.WTW))];
};

export const tableReductionHeaders = leg => {
    const { reductionTable } = leg;
    const reductionLevel = 'Reduction level';
    const reduction = 'Emissions reduced (t CO₂e)';
    const rest = 'Emissions remaining (t CO₂e)';
    const price = 'Total price';

    return (
        [
            reductionLevel,
            ...(reductionTable.showReduction ? [reduction] : []),
            ...(reductionTable.showRest ? [rest] : []),
            ...(reductionTable.showTotalPrice ? [price] : []),
        ]
    )
};

export const tableReductionRow = (leg, isLanemeters) => {
    const unit = leg.modality === 'ship' && isLanemeters ? 'lanemeter' : 'trailer';
    const reductionLevel = ':number:priceunit';
    const ttwwwt = ':ttw TTW \n(:wtw WTW)';
    const priceString = leg.priceUnit === constants.general.TRAILER ? '€:price' : '€:ttw TTW \n(€:wtw WTW)';
    return Object.values(leg.reductionTable.scenarios)
        .filter(scenario => !!scenario)
        .map(scenario => {
            const { reductionTable } = leg;
            const reduction = leg.modality === 'truck' ? truckReductions(leg, scenario) : shipReductions(leg, scenario / 100);
            const emission = leg.modality === 'truck' ? truckEmissions(leg) : leg.emission;
            let priceText = leg.priceUnit === constants.general.TRAILER ?
                priceString.replace(':price', formatNumber(leg.reductionPrice * scenario)) :
                priceString.replace(':ttw', formatNumber(parseFloat(leg.reductionPrice) * reduction.leg.TTW[unit])).replace(':wtw', formatNumber(parseFloat(leg.reductionPrice) * reduction.leg.WTW[unit]))
            return [
                reductionLevel.replace(':number', formatNumber(scenario)).replace(':priceunit', leg.modality === 'truck' ? ` of ${formatNumber(leg.trailers)} trailers` : '%'),
                ...(reductionTable.showReduction ? [ttwwwt.replace(':ttw', formatNumber(reduction.leg.TTW[unit])).replace(':wtw', formatNumber(reduction.leg.WTW[unit]))] : []),
                ...(reductionTable.showRest ? [ttwwwt.replace(':ttw', formatNumber(emission.leg.TTW[unit] - reduction.leg.TTW[unit])).replace(':wtw', formatNumber(emission.leg.WTW[unit] - reduction.leg.WTW[unit]))] : []),
                ...(reductionTable.showTotalPrice ? [priceText] : []),
            ]
        });
};

export const getColumnWidth = type => {
    let legsSize = 150;
    let columnSize = (constants.slides.size.width - 2 * constants.slides.padding.x - legsSize) / 6;
    switch (type) {
        case constants.leg.LEG:
            legsSize = 80;
            columnSize = (constants.slides.leg.content.width - 2 * constants.slides.padding.x - legsSize) / 5;
            return [
                legsSize + 'px',
                ...Array(5).fill(columnSize + 'px')
            ];
        case constants.table.FIVE_COLUMNS:
            legsSize = (constants.slides.size.width - 2 * constants.slides.padding.x) - 4 * columnSize;
            return [
                legsSize + 'px',
                ...Array(4).fill(columnSize + 'px')
            ];
        default:
            return [
                legsSize + 'px',
                ...Array(6).fill(columnSize + 'px')
            ];
    }
}

export default constants;