import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import constants, { tableReductionHeaders, tableReductionRow } from "../../../../constants";
import colors from "../../../../styles/colors";

const TableReductionSlide = ({ leg, isLanemeters }) => {
    const headers = tableReductionHeaders(leg);
    const rows = tableReductionRow(leg, isLanemeters);

    return (
        <Box w="100%">
            <Grid templateColumns={`repeat(${headers.length}, ${(constants.slides.leg.content.width - 2 * constants.slides.padding.x) / headers.length}px)`}>
                {headers.map(header => (
                    <GridItem>
                        <Flex borderBottom={`1px solid ${colors.dfds.slide.lime}`} color="white" alignItems="flex-end" h="100%" px={2} py={1} fontSize={9} fontWeight="bold">{header}</Flex>
                    </GridItem>
                ))}
                {rows.map(scenario => scenario.map(column => (
                    <GridItem>
                        <Flex h="100%" fontSize={9} px={2} py={1} alignItems="center" color="white">{column}</Flex>
                    </GridItem>
                )))}
            </Grid>
        </Box>
    )
}

export default TableReductionSlide;