import { cloneElement, useEffect, useState } from "react";
import { getEmissions } from "../api";
import constants, { reductionOptions } from "../constants";
import { shipEmissions, shipReductions, truckEmissions, truckReductions } from "../calculations";


const CustomiseTableContainer = ({ children, legs: propsLegs, userToken }) => {
    const initTable = { legs: [], includeLanemeters: false };
    const [tables, setTables] = useState({
        downloadSettings: {
            isKg: false,
            isLanemeters: null
        },
        1: { id: 1, ...initTable },
        2: { id: 2, ...initTable, added: false }
    });

    const [emissionsTimeStamp, setEmissionsTimeStamp] = useState('');

    useEffect(() => {
        getEmissions(userToken).then(res => {
            setEmissionsTimeStamp(res.timeStamp);
            let newLegs = [];
            for (let i = 0; i < propsLegs.length; i++) {
                const leg = propsLegs[i];
                const emission = leg.modality === 'ship' ? shipEmissions(leg, res.emissions, 'unit') : truckEmissions(leg, leg.type,)
                newLegs = [...newLegs, { ...leg, emission }];
            };
            setTables(tables => ({ ...tables, downloadSettings: { ...tables.downloadSettings }, 1: { ...tables[1], legs: newLegs }, 2: { ...tables[2], legs: newLegs } }));
        });
    }, [propsLegs]);

    const updateReductionType = (table, index, reductionType) => {
        setTables(tables => ({
            ...tables,
            [table]: {
                ...tables[table],
                legs: [
                    ...tables[table].legs.slice(0, index),
                    {
                        ...tables[table].legs[index],
                        reductionType,
                        ...((reductionType === constants.table.NOT_INCLUDE || reductionType === '') ? { reductionPrice: null } : {})
                    },
                    ...tables[table].legs.slice(index + 1)]
            }
        }));
    };

    const updateReductionSize = (table, index, value) => {
        let newLeg = { ...tables[table].legs[index] };
        newLeg = {
            ...newLeg,
            reduction: newLeg.modality === 'ship' ? {
                percent: value,
                tonne: shipReductions(newLeg, value / 100)
            } : {
                number: value,
                percent: value / newLeg.trailers * 100,
                tonne: truckReductions(newLeg, value)
            }
        };
        setTables(tables => ({
            ...tables,
            [table]: {
                ...tables[table],
                legs: [
                    ...tables[table].legs.slice(0, index),
                    newLeg,
                    ...tables[table].legs.slice(index + 1)]
            }
        }));
    }

    const updatePrice = (table, index, reductionPrice) => {
        setTables(tables => ({
            ...tables,
            [table]: {
                ...tables[table],
                legs: [
                    ...tables[table].legs.slice(0, index),
                    { ...tables[table].legs[index], reductionPrice },
                    ...tables[table].legs.slice(index + 1)]
            }
        }));
    };

    const updatePriceUnit = (table, index, priceUnit) => {
        setTables(tables => ({
            ...tables,
            [table]: {
                ...tables[table],
                legs: [
                    ...tables[table].legs.slice(0, index),
                    { ...tables[table].legs[index], priceUnit },
                    ...tables[table].legs.slice(index + 1)]
            }
        }));
    }

    const updateTrailerPrice = (table, index, trailerPrice) => {
        setTables(tables => ({
            ...tables,
            [table]: {
                ...tables[table],
                legs: [
                    ...tables[table].legs.slice(0, index),
                    { ...tables[table].legs[index], trailerPrice },
                    ...tables[table].legs.slice(index + 1)]
            }
        }));
    }

    const toggleSecondTable = () => setTables(tables => ({
        ...tables,
        2: {
            ...tables[2],
            added: !tables[2].added
        }
    }));

    const toggleLanemeters = table => setTables(tables => ({
        ...tables,
        [table]: {
            ...tables[table],
            includeLanemeters: !tables[table].includeLanemeters
        }
    }));

    const toggleDownloadSettings = (type, isChecked) => {
        setTables(tables => ({ ...tables, downloadSettings: { ...tables.downloadSettings, [type]: isChecked } }))
    };

    return cloneElement(children, { tables, emissionsTimeStamp, reductionOptions, updateReductionType, toggleSecondTable, toggleLanemeters, toggleDownloadSettings, updatePrice, updatePriceUnit, updateReductionSize, updateTrailerPrice });
};

export default CustomiseTableContainer;