import React from "react";
import { Box } from "@chakra-ui/react";
import MapViz from "./MapViz";
import MapLegend from "./MapLegend";
import { legendsCondition } from "../../../helpers";

const Map = ({ legs, passMapNode }) => (
    <Box pos="relative" h="100%" w="100%">
        <MapViz legs={legs} passMapNode={passMapNode} />
        <MapLegend legendsCondition={legendsCondition(legs)} />
    </Box>
);

export default Map;