import { cloneElement, useEffect, useState } from "react";
import { getEmissions } from "../api";
import { enrichLegsWithPaths } from "../helpers";
import { enrichLegsWithEmissions } from "../calculations";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../paths";

const CustomiseMapContainer = ({ children, legs: propsLegs, step, userToken }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [leg, setLeg] = useState({});
    const [legs, setLegs] = useState([]);
    const [totalEmission, setTotalEmission] = useState({ TTW: 0, WTW: 0 });
    const initSettings = {
        downloadSettings: {
            isKg: false,
            isLanemeters: false
        },
    };
    const [settings, setSettings] = useState(initSettings);

    useEffect(() => {
        if (step === 'output') {
            if (
                !leg ||
                !leg.reductionType ||
                !leg.reductionPrice ||
                (leg.reductionTable && Object.values(leg.reductionTable.scenarios).filter(scenario => scenario && scenario !== '').length === 0)
            ) {
                navigate(paths.legSettings.replace(':id', id), { state: { leg } });
            }
        }
    }, [step, leg]);

    useEffect(() => {
        if (propsLegs.length > 0) {
            getEmissions(userToken).then(async res => {
                const { legs } = await enrichLegsWithPaths(propsLegs, userToken);
                const { legs: newLegs, totalEmission } = enrichLegsWithEmissions(legs, res.emissions);
                setLegs(newLegs);
                setTotalEmission(totalEmission);
            });
        }
    }, [propsLegs]);

    const updateLeg = leg => setLeg(leg);

    const updateSettings = settings => setSettings(settings);

    return cloneElement(children, { leg, legs, updateLeg, settings, updateSettings });
};

export default CustomiseMapContainer;