import React from "react";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-left.svg";
import Button from "..";

const BackButton = ({ onClick }) => (
    <Button
        leftIcon={<Arrow fill="white" />}
        colorScheme="dfds"
        text="Back"
        onClick={onClick}
    />
);

export default BackButton;