import React, { useEffect, useState } from "react";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../paths";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import ConfigureSettingsLeg from "../../blocks/ConfigureSettingsLeg/ConfigureSettingsLeg";
import Add from "../../forms/Add/Add";
import ConfigureSettingsReductionLeg from "../../blocks/ConfigureSettingsReductionLeg/ConfigureSettingsReductionLeg";
import Remove from "../../forms/Remove/Remove";

const Configure = ({ legs, leg, updateLeg }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [createIsDisabled, setCreateIsDisabled] = useState(true);

    useEffect(() => {
        if (
            !leg ||
            !leg.reductionType ||
            (leg.modality === 'ship' && !leg.reductionPrice) ||
            (leg.modality === 'truck' && (!leg.reductionPrice || !leg.priceUnit)) ||
            (leg.reductionTable && Object.values(leg.reductionTable.scenarios).filter(scenario => scenario && scenario !== '').length === 0)
        ) {
            setCreateIsDisabled(true);
        } else {
            setCreateIsDisabled(false);
        }
    }, [leg]);

    return (
        <>
            <Flex h="100%" py="6">
                <Stack gap="2rem">
                    <ConfigureSettingsLeg
                        legs={legs}
                        leg={leg}
                        updateLeg={updateLeg}
                    />
                    {leg.reductionTable ? (
                        <>
                            <ConfigureSettingsReductionLeg table={{ ...leg.reductionTable, modality: leg.modality, trailers: leg.trailers }} updateTable={reductionTable => updateLeg({ ...leg, reductionTable })} />
                            <Remove onClick={() => updateLeg({ ...leg, reductionTable: null })} />
                        </>
                    ) : (
                        <Add text="Add reduction table" onClick={() => updateLeg({ ...leg, reductionTable: { scenarios: { 1: '', 2: '', 3: '' }, showReduction: false, showRest: false, showTotalPrice: false } })} />
                    )}
                </Stack>
            </Flex>
            <Flex justifyContent="space-between">
                <BackButton onClick={() => navigate(paths.outputs.replace(':id', id))} />
                <Button
                    isDisabled={createIsDisabled}
                    colorScheme="dfds"
                    onClick={() => navigate(paths.legOutput.replace(':id', id))}
                >
                    Create
                </Button>
            </Flex>
        </>
    )
};

export default Configure;