import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Flex, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import paths from "../../../paths";
import BackButton from "../../forms/buttons/BackButton/BackButton";
import Table from "../../visualizations/tables/Table/Table";
import constants from "../../../constants";
import Heading from "../../typography/Heading/Heading";
import InputAddon from "../../forms/InputAddon/InputAddon";
import ErrorAlert from "../../overlay/alert/ErrorAlert/ErrorAlert";

const Configure = ({ error, resetError, settings, updateSettings, legs, updateReduction, reductionOptions, updateFuelType }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isCreateDisabled, setIsCreateDisabled] = useState(true);

    const settingsOptions = constants.map;

    useEffect(() => {
        switch (settings.option) {
            case settingsOptions.DEFAULT_CO2E_EMISSIONS:
                setIsCreateDisabled(false);
                return;
            case settingsOptions.REDUCED_CO2E_SCENEARIO:
            case settingsOptions.BOTH_SCENEARIOS:
                setIsCreateDisabled(legs.filter(leg => {
                    switch (true) {
                        case leg.fuelType === constants.map.DEFAULT_FUEL:
                        case !leg.fuelType && !leg.reduction:
                        case !leg.fuelType && (leg.reduction && !leg.reduction.percent):
                        case leg.fuelType && leg.reduction?.percent > 0:
                            return false;
                        default:
                            return true;
                    }
                }).length !== 0);
                return;
            case '':
            default:
                setIsCreateDisabled(true);
                return;
        }
    }, [legs, settings, settingsOptions]);

    return (
        <>
            {!!error && <ErrorAlert error={error} onClick={resetError} />}
            <Flex h="100%" py="6">
                <Stack gap="2rem">
                    <Heading size="sm" text="Map settings" />
                    <Flex>
                        <RadioGroup value={settings.option} onChange={value => updateSettings({ ...settings, option: value })} colorScheme="dfds">
                            <Stack gap="1rem">
                                <Radio value={settingsOptions.DEFAULT_CO2E_EMISSIONS}>Show default CO₂e emissions</Radio>
                                <Radio value={settingsOptions.REDUCED_CO2E_SCENEARIO}>Show a reduced CO₂e scenario</Radio>
                                <Radio value={settingsOptions.BOTH_SCENEARIOS}>Show both default/reduced</Radio>
                            </Stack>
                        </RadioGroup>
                    </Flex>
                    {(settings.option === settingsOptions.BOTH_SCENEARIOS || settings.option === settingsOptions.REDUCED_CO2E_SCENEARIO) && (
                        <>
                            <Heading size="sm" text="Reduction Scenario" />
                            <Box>
                                <Table
                                    headers={["Leg", "Fuel type", "Reduction size"]}
                                    rows={legs.map((leg, index) => ([
                                        `${leg.places.origin.address} - ${leg.places.destination.address}`,
                                        <Select
                                            size="sm"
                                            w="15rem"
                                            placeholder={`Select ${leg.modality === 'truck' ? 'land' : 'sea'} fuel`}
                                            value={leg.fuelType || ''}
                                            onChange={e => {
                                                updateReduction('fuelType', index, e.target.value);
                                            }}
                                        >
                                            {Object.entries({ ...reductionOptions[leg.modality], [constants.map.DEFAULT_FUEL]: constants.map.strings.DEFAULT_FUEL }).map(([key, value]) => (
                                                <option value={key}>{value}</option>
                                            ))}
                                        </Select>,
                                        <Flex gap="1rem">
                                            {!(leg.fuelType === constants.map.DEFAULT_FUEL || leg.fuelType === '' || !leg.fuelType) && (
                                                <InputAddon
                                                    text={leg.modality === 'truck' ? `of ${leg.trailers} trailers` : "%"}
                                                    value={(leg.modality === 'truck' ? leg.reduction?.number : leg.reduction?.percent) || ''}
                                                    onChange={e => updateReduction('reductionFactor', index, e.target.value)}
                                                />
                                            )}
                                        </Flex>
                                    ]))}
                                />
                            </Box>
                        </>
                    )}
                </Stack>
            </Flex>
            <Flex justifyContent="space-between">
                <BackButton onClick={() => navigate(paths.outputs.replace(':id', id))} />
                <Button
                    colorScheme="dfds"
                    isDisabled={isCreateDisabled}
                    onClick={() => navigate(paths.mapOutput.replace(':id', id), { state: { settings } })}
                >
                    Create Map
                </Button>
            </Flex>
        </>
    )
};

export default Configure;