import React from "react"
import Button from ".."

const PrimaryButton = ({ onClick, text, isDisabled, isLoading }) => (
	<Button
		isDisabled={isDisabled}
		text={text}
		onClick={onClick}
		colorScheme="dfds"
		isLoading={isLoading}
	/>
);

export default PrimaryButton;